import {lazy, FC, Suspense} from 'react'
import {Route, Routes, Navigate} from 'react-router-dom'
import {MasterLayout} from '../../_metronic/layout/MasterLayout'
import TopBarProgress from 'react-topbar-progress-indicator'
import {getCSSVariableValue} from '../../_metronic/assets/ts/_utils'
import {WithChildren} from '../../_metronic/helpers'
import Home from '../modules/home'
import {Error404} from '../modules/errors/components/Error404'
import Error403 from '../modules/errors/components/Error403'

const PrivateRoutes = () => {
  const ProfilePage = lazy(() => import('../modules/profile/ProfilePage'));
  const AdministrationPage = lazy(() => import('../modules/administration/AdministrationPages'));
  const AuxiliaryTables = lazy(() => import('../modules/auxiliaryTables/AuxiliaryPage'));
  const Request = lazy(() => import('../modules/requests/RequestPage'));
  const CentralRequests = lazy(() => import('../modules/centralRequests/index'));
  const RequestForTranslation = lazy(() => import('../modules/requestForTranslation/index'));
  const TranslateRequest = lazy(() => import('../modules/translateRequests/index'));
  const CreateUser = lazy(() => import('../modules/users/User'));
  const MonitorRequestList = lazy(() => import('../modules/monitorRequests/Monitor'));
  const Manuals = lazy(() => import('../modules/manuals/index'));
  // const WizardsPage = lazy(() => import('../modules/wizards/WizardsPage'))
  // const AccountPage = lazy(() => import('../modules/accounts/AccountPage'))
  // const ChatPage = lazy(() => import('../modules/apps/chat/ChatPage'))
  // const UsersPage = lazy(() => import('../modules/apps/user-management/UsersPage'))
  //const  Horizontal = lazy(() => import( '../modules/createRequest/requestPage'))
  // const  Vertical = lazy(() => import( '../modules/createRequest/requestPage'))

  return (
    <Routes>
      <Route element={<MasterLayout />}>
        <Route path='auth/*' element={<Navigate to='/home' />} />
        <Route path='home' element={<Home />} />
        <Route
          path='users/*'
          element={
            <SuspensedView>
              <CreateUser />
            </SuspensedView>
          }
        />
        <Route
          path='crafted/pages/profile/*'
          element={
            <SuspensedView>
              <ProfilePage />
            </SuspensedView>
          }
        />
        <Route
          path='administration/*'
          element={
            <SuspensedView>
              <AdministrationPage />
            </SuspensedView>
          }
        />
        <Route
          path='configurations/*'
          element={
            <SuspensedView>
              <AuxiliaryTables />
            </SuspensedView>
          }
        />
        <Route
          path='requests/*'
          element={
            <SuspensedView>
              <Request />
            </SuspensedView>
          }
        />
        <Route
          path='monitor/*'
          element={
            <SuspensedView>
              <MonitorRequestList />
            </SuspensedView>
          }
        />
        <Route
          path='requestForTranslation/*'
          element={
            <SuspensedView>
              <RequestForTranslation />
            </SuspensedView>
          }
        />
        <Route
          path='requestForTranslation/:id'
          element={
            <SuspensedView>
              <RequestForTranslation />
            </SuspensedView>
          }
        />
        <Route
          path='centralRequests/*'
          element={
            <SuspensedView>
              <CentralRequests />
            </SuspensedView>
          }
        />
        <Route
          path='translateRequests/*'
          element={
            <SuspensedView>
              <TranslateRequest />
            </SuspensedView>
          }
        />
        <Route
          path='manuals/*'
          element={
            <SuspensedView>
              <Manuals />
            </SuspensedView>
          }
        />
        <Route path='*' element={<Navigate to='/error/404' />} />
        <Route path='error/403' element={<Error403 />} />
        <Route path='error/404' element={<Error404 />} />
      </Route>
    </Routes>
  )
}

const SuspensedView: FC<WithChildren> = ({children}) => {
  const baseColor = getCSSVariableValue('--bs-primary')
  TopBarProgress.config({
    barColors: {
      '0': baseColor,
    },
    barThickness: 1,
    shadowBlur: 5,
  })
  return <Suspense fallback={<TopBarProgress />}>{children}</Suspense>
}

export {PrivateRoutes}
