import axios from "axios";
import { RoleManual } from "./models";

const API_URL = process.env.REACT_APP_API_URL

const API_MANUALS=`${API_URL}/api/Manuals/RoleManuals`;
const DOWNLOAD_MANUAL = `${API_URL}/api/Manuals/DownloadManual`;

export const fetchManuals = async (): Promise<RoleManual[]> => {
    try {
      const response = await axios.get<RoleManual[]>(API_MANUALS);
      return response.data;
    } catch (error) {
      console.error('Error fetching manuals:', error);
      throw error;
    }
};

export const downloadManual = async (id: number): Promise<any> => {
  try {
    const response = await axios.post(DOWNLOAD_MANUAL, { Id: id }, { responseType: 'blob' });
    return response.data;
  } catch (error) {
    console.error("There has been an error while fetching data, DownloadDocument:", error);
    return null;
  }
};