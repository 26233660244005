import { useEffect, useRef, useState } from "react";
import ApexCharts, { ApexOptions } from "apexcharts";
import { getCSSVariableValue } from "../../../../_metronic/assets/ts/_utils";
import { KTSVG } from "../../../../_metronic/helpers/components/KTSVG";

import '../styles/CentralLevelDashboard.css';
import CustomTooltip from "./CustomTooltip";

type ProgressWidgetProps = {
    chartColor: string;
    chartHeight: string;
    nfpgj: number;
    nfpp: number;
    np: number;
    kpm: number;
    nofm: number;
    total: number;
    court: boolean;
    name: string;
    subName?: string;
    percentage: number;
    workload?: string;
    isActive: boolean;
    className?: string;
    maxWidth?: string;
    onClick?: () => void;
}

const ProgressWidget: React.FC<ProgressWidgetProps> = ({
    chartColor,
    chartHeight,
    nfpgj,
    nfpp,
    np,
    kpm,
    nofm,
    total,
    court,
    name,
    subName,
    percentage,
    workload,
    isActive,
    className,
    maxWidth,
    onClick
}) => {
    const chartRef = useRef<HTMLDivElement | null>(null);

    const refreshChart = () => {
        if (!chartRef.current) {
            return;
        }

        const options = chartOptions(chartColor, chartHeight, percentage, workload);
        if (!options.chart || !options.chart.type) {
            return;
        }

        const chart = new ApexCharts(chartRef.current, options);
        if (chart) {
            chart.render();
        }

        return chart;
    }

    useEffect(() => {
        const chart = refreshChart();

        return () => {
            if (chart) {
                chart.destroy();
            }
        }
    }, [chartRef, percentage, workload]);

    return (
        <div
            className={`card card-xl-stretch mb-xl-2 cursor-pointer shadow-sm card-hover ${className} ${isActive ? 'card-active' : ''}`}
            style={{ width: '100%', minWidth: '140px', maxWidth: (maxWidth ? maxWidth : '210px'), height: '100%', minHeight: '221px' }}
        >
            <div
                className="card-header border-bottom-0 d-flex align-items-center justify-content-end p-0"
                style={{ minHeight: '35px', maxHeight: '35px' }}
            >
                <div className="card-toolbar">
                    <CustomTooltip
                        NFPGJ={nfpgj.toString()}
                        NFPP={nfpp.toString()}
                        NP={np.toString()}
                        KPM={kpm.toString()}
                        NOfM={nofm.toString()}
                        Total={total.toString()}
                        Court={court}
                    >
                        <button type="button" className="btn btn-sm btn-icon btn-color-primary btn-active-light-primary">
                            <KTSVG path="media/icons/duotune/general/gen045.svg" className="svg-icon-muted svg-icon-2hx" />
                        </button>
                    </CustomTooltip>
                </div>
            </div>
            <div className='card-body d-flex flex-column p-1 pb-3' onClick={onClick}>
                <div ref={chartRef} className='mixed-widget-4-chart'></div>
                <div className="d-flex justify-content-center align-items-center flex-column">
                    <span className="card-label fw-bold fs-6 text-center" style={{ whiteSpace: 'collapse' }}>{name}</span>
                    {subName && (<span className="card-label fw-bold fs-7 text-center" style={{ whiteSpace: 'collapse' }}>{subName}</span>)}
                </div>
            </div>
        </div>
      )
}

const chartOptions = (chartColor: string, chartHeight: string, percentage: number, workload: string | undefined): ApexOptions => {
    const baseColor = getCSSVariableValue('--bs-' + chartColor);
    const lightColor = getCSSVariableValue('--bs-' + chartColor + '-light');
    const labelColor = getCSSVariableValue('--bs-gray-700');

    return {
        series: [percentage],
        chart: {
            fontFamily: 'inherit',
            height: chartHeight,
            type: 'radialBar'
        },
        plotOptions: {
            radialBar: {
                hollow: {
                    margin: 0,
                    size: '65%',
                    position: 'back',
                    dropShadow: {
                        enabled: true,
                        top: 0,
                        left: 0,
                        blur: 3,
                        opacity: 0.5
                    }
                },
                dataLabels: {
                    name: {
                        show: workload !== undefined,
                        color: labelColor,
                        fontSize: '14px',
                        fontWeight: '600',
                        offsetY: -5
                    },
                    value: {
                        show: true,
                        color: labelColor,
                        fontSize: '16px',
                        fontWeight: '700',
                        offsetY: workload !== undefined ? 5 : 8,
                        formatter: function (val) {
                            return `${val}%`;
                        }
                    }
                },
                track: {
                    background: lightColor,
                    strokeWidth: '100%'
                }
            },
        },
        colors: [baseColor],
        stroke: {
            lineCap: 'round'
        },
        labels: [workload !== undefined ? workload : '']
    }
}

export default ProgressWidget;