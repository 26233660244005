import React, {FC} from 'react'
import {t} from 'i18next'

const ToggleHelpDrawer: FC = () => (
  <>
    <button
      id='kt_help_toggle'
      className='engage-help-toggle btn btn-flex h-35px bg-body btn-color-gray-700 btn-active-color-gray-900 shadow-sm px-5 rounded-top-0'
      data-bs-toggle='tooltip'
      data-bs-placement='left'
      data-bs-dismiss='click'
      data-bs-trigger='hover'
    >
      {t('manuals')}
    </button>
    <button
      id='request_videos'
      className='engage-help-toggle btn btn-flex h-35px bg-body btn-color-gray-700 btn-active-color-gray-900 shadow-sm px-5 rounded-top-0'
      data-bs-toggle='tooltip'
      data-bs-placement='left'
      data-bs-dismiss='click'
      data-bs-trigger='hover'
    >
      {t('request_for_translation')}
    </button>
  </>
)

export {ToggleHelpDrawer}
