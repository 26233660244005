export interface RequestVM {
    RequestId: number;
    RequestTranslateId: number;
    RequestNumber: string;
    InsertedDate: string;
    PersonName: string;
    Institution: string;
    DocumentType: string;
    DocumentName: string;
    NewDocumentName: string;
    PriorityType: string;
    DeadlineDate: string;
    PageNumber: number;
    Confidential: boolean;
    OriginalLanguage: string;
    NewLanguage: string;
    DocumentExtension: string;
    NewDocumentExtension: string;
    WithParts: boolean;
    PageFrom?: number;
    PageTo?: number;
    StatusTypeId: StatusType;
    Documents: Document[];
    Statuses: Status[];
}

export enum StatusType {
    SentForTranslation = 1,
    ProcessOfTranslation,
    Translated,
    TransferRequest,
    Suspended = 6,
    AppointmentInterpreter,
    InterruptTranslation,
    ForApproval,
    Deleted,
    TransferOfRequest
}

export enum ReportType {
    PDF = 1,
    Excel,
    Word,
    PowerPoint,
    XML,
    CSV,
    JSON,
    Image,
    HTML,
    Doc
}

interface Document {
    Id: number;
    FullName: string;
    DocumentName: string;
    Extension: string;
    PageFrom: number;
    PageTo: number;
}

export interface Status {
    StatusTitle: string;
    InsertedFrom: string;
    Comment: string;
    Date: string;
    Active: boolean;
}