import {useState} from 'react';
import i18next from 'i18next';
import * as Yup from 'yup';
import clsx from 'clsx';
import {useFormik} from 'formik';
import {getUserByToken, login} from '../core/_requests';
import {useAuth} from '../core/Auth';
import { useTranslation } from 'react-i18next';
import { LanguageEnum } from '../core/_models';

const I18N_CONFIG_KEY = process.env.REACT_APP_I18N_CONFIG_KEY || 'i18nConfig';
const loginSchema = Yup.object().shape({
  email: Yup.string().required('Kjo fushë është obligative'),
  password: Yup.string().required('Kjo fushë është obligative')
});

export function Login() {
  const [loading, setLoading] = useState(false)
  const {saveAuth, setCurrentUser} = useAuth()
  const {t, i18n} = useTranslation()
  const [languageId, setLanguageId] = useState<LanguageEnum>(LanguageEnum.ALBANIAN);

  const formik = useFormik({
    initialValues: {
      email: '',
      password: ''
    },
    validationSchema: loginSchema,
    onSubmit: async (values, {setStatus, setSubmitting}) => {
      setLoading(true)
      try {
        const {data: auth, status} = await login(values.email, values.password, languageId)
        if (status === 200 && auth) {
          saveAuth(auth)
          const {data: user} = await getUserByToken(auth.api_token)
          setCurrentUser(user)
        } else if (status === 423) {
          setStatus('Llogaria është bllokuar')
        } else {
          setStatus('Përpjekje e pavlefshme për kyqje. Emaili ose fjalëkalimi janë të pasakta.')
        }
      } catch (error) {
        setStatus('Përpjekje e pavlefshme për kyqje. Emaili ose fjalëkalimi janë të pasakta.')
      }
      setSubmitting(false)
      setLoading(false)
    },
  })

  const handleLanguageChange = (language: LanguageEnum) => {
    var languageStr = 'sq';
    if (language === LanguageEnum.ALBANIAN) {
      languageStr = 'sq';
    } else if (language === LanguageEnum.ENGLISH) {
      languageStr = 'en';
    } else {
      languageStr = 'sr';
    }
    localStorage.setItem(I18N_CONFIG_KEY, JSON.stringify({selectedLang: languageStr}))
    i18next.changeLanguage(languageStr);

    setLanguageId(language);
  }

  return (
    <form
      className='form w-100'
      onSubmit={formik.handleSubmit}
      noValidate
      id='kt_login_signin_form'
    >
      <div className='d-flex mb-10 rounded'>
        <img
          alt='Logo'
          src='/media/illustrations/Manifest/court.png'
          className='h-100px me-3 mt-3'
        />
        <div className='align-self-center ms-5'>
          <div className='login-box-msg fw-bolder fs-3'>{t("republif_of_kosovo")}</div>
          <div className='login-box-msg fw-bold fs-4'>{t("The_Judicial_Council_Of_Kosovo")}</div>
          <div className='login-box-msg fs-5'>{t("Login_To_Start_Your_Session")}</div>
        </div>
      </div>

      <div className='text-center mb-8 mt-4'>
        <h3 className='text-gray-700 fw-bold'>{t("identify")}</h3>
        <div className="text-center mb-5">
					<a className={`me-1 cursor-pointer ${languageId === LanguageEnum.ALBANIAN ? "fw-bolder" : "fw-semibold"}`} onClick={()=> handleLanguageChange(LanguageEnum.ALBANIAN)}>Shqip</a>|
					<a className={`mx-1 cursor-pointer ${languageId === LanguageEnum.ENGLISH ? "fw-bolder" : "fw-semibold"}`} onClick={()=> handleLanguageChange(LanguageEnum.ENGLISH)}>English</a>|
					<a className={`ms-1 cursor-pointer ${languageId === LanguageEnum.SERBIAN ? "fw-bolder" : "fw-semibold"}`} onClick={()=> handleLanguageChange(LanguageEnum.SERBIAN)}>Srpski</a>
				</div>
        <div className='text-gray-500 fw-bold fs-6'>
          {t("please_enter_your_email_and_password")}
        </div>
      </div>

      {formik.status ? (
        <div className='mb-lg-10 alert alert-danger'>
          <div className='alert-text font-weight-bold'>{formik.status}</div>
        </div>
      ) : null}
      <div className='fv-row mb-8'>
        <label className='form-label fs-6 fw-bolder text-dark'>Email</label>
        <input
          placeholder='Email'
          {...formik.getFieldProps('email')}
          className={clsx(
            'form-control bg-transparent',
            {'is-invalid': formik.touched.email && formik.errors.email},
            {
              'is-valid': formik.touched.email && !formik.errors.email,
            }
          )}
          type='email'
          name='email'
          autoComplete='off'
        />
        {formik.touched.email && formik.errors.email && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              <span role='alert' className='fw-bold text-danger'>
                {formik.errors.email}
              </span>
            </div>
          </div>
        )}
      </div>

      <div className='fv-row mb-3'>
        <label className='form-label fw-bolder text-dark fs-6 mb-0'>{t("password")}</label>
        <input
          placeholder={t("password")}
          type='password'
          autoComplete='off'
          {...formik.getFieldProps('password')}
          className={clsx(
            'form-control bg-transparent',
            {
              'is-invalid': formik.touched.password && formik.errors.password,
            },
            {
              'is-valid': formik.touched.password && !formik.errors.password,
            }
          )}
        />
        {formik.touched.password && formik.errors.password && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              <span role='alert' className='fw-bold text-danger'>
                {formik.errors.password}
              </span>
            </div>
          </div>
        )}
      </div>
      <div className='d-grid mb-10'>
        <button
          type='submit'
          id='kt_sign_in_submit'
          className='btn btn-primary'
          disabled={formik.isSubmitting || !formik.isValid}
        >
          {!loading && <span className='indicator-label'>{t('log_in')}</span>}
          {loading && (
            <span className='indicator-progress' style={{display: 'block'}}>
              {t("please_wait")}
              <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
            </span>
          )}
        </button>
      </div>
    </form>
  )
}
