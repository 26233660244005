import React, { FC } from 'react';
import { useLang } from './Metronici18n';
import { IntlProvider } from 'react-intl';

import translations from '../../translations/translations.json'
import { WithChildren } from '../helpers';

import '@formatjs/intl-numberformat/polyfill';
import '@formatjs/intl-numberformat/locale-data/en';
import '@formatjs/intl-numberformat/locale-data/sq';
import '@formatjs/intl-relativetimeformat/polyfill';
import '@formatjs/intl-relativetimeformat/locale-data/en';
import '@formatjs/intl-relativetimeformat/locale-data/sq';

const I18nProvider: FC<WithChildren> = ({ children }) => {
  const locale = useLang();
  const messages = translations[locale];

  return (
    <IntlProvider locale={locale} messages={messages}>
      {children}
    </IntlProvider>
  );
};

export { I18nProvider };
