import { Skeleton } from "@mui/material";
import React from "react";

interface CardProps {
    color: 'primary' | 'success' | 'info' | 'dark' | 'warning' | 'danger' | 'white' | 'secondary';
    light?: boolean;
    icon: React.ReactNode;
    text: string;
    stat: number | string;
    total?: number | null;
    loading: boolean;
}

const StatsCard: React.FC<CardProps> = ({ color, light = false, icon, text, stat, total, loading }) => {
    const lines = text.split('\n');
    return (
        <div className={`card bg${(light ? '-light' : '')}-${color} hoverable`}>
            <div className="card-body">
                {icon}
                <div className={`text-${(color === 'white' || color === 'secondary' || light ? 'dark' : 'white')} fw-bold fs-3 mb-2 mt-5`}>
                    {loading ? <Skeleton /> : (total ? `${stat}/${total}` : stat)}
                </div>
                <div className={`text-${(color === 'white' || color === 'secondary' || light ? 'dark' : 'white')} fw-semibold fs-7`}>
                    {loading ? <Skeleton /> : lines.map((line, index) => (
                        <React.Fragment key={index}>
                            {line}
                            {index < lines.length - 1 && <br />}
                        </React.Fragment>
                    ))}
                </div>
            </div>
        </div>
    )
}

export default StatsCard;