import DescriptionData from './DescriptionData';
import '../styles/main.css';

interface DocumentDataProps {
    ManualId: number;
    DocumentName: string;
    Extension: string;
    InsertedDate: string;
    onClick: (requestId: number) => void;
}

const DocumentData: React.FC<DocumentDataProps> = ({
    ManualId,
    DocumentName,
    Extension,
    InsertedDate,
    onClick
}) => {
    const logoMap: { [key: string]: string } = {
        '.pdf': require('../../../../../_metronic/media/svg/files/pdf.svg').default,
        '.doc': require('../../../../../_metronic/media/svg/files/doc.svg').default,
        '.docx': require('../../../../../_metronic/media/svg/files/doc.svg').default
    };
    const logo = logoMap[Extension] || require('../../../../../_metronic/media/svg/files/folder-document.svg').default;

    return (
        <div className="card h-100 card-hover" style={{ cursor: 'pointer' }} onClick={() => onClick(ManualId)}>
            <div className="card-body d-flex justify-content-start align-items-center px-4">
                <div className="symbol symbol-50px">
                    <img src={logo} alt="" />
                </div>
                <div className="ms-4 text-gray-800 d-flex flex-column">
                    <div className="fs-5 fw-bolder mb-2">
                        <DescriptionData description={DocumentName} length={60}/>
                    </div>
                    {InsertedDate && (<div className="fs-7 fw-bold text-gray-500 mt-auto">{InsertedDate}</div>)}
                </div>
            </div>
        </div>
    )
}

export default DocumentData;