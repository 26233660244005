import { TFunction } from "i18next";

export const getTranslatedMonths = (t: TFunction) => [
    { MonthId: 1, Name: t("january") },
    { MonthId: 2, Name: t("february") },
    { MonthId: 3, Name: t("march") },
    { MonthId: 4, Name: t("april") },
    { MonthId: 5, Name: t("may") },
    { MonthId: 6, Name: t("june") },
    { MonthId: 7, Name: t("july") },
    { MonthId: 8, Name: t("august") },
    { MonthId: 9, Name: t("september") },
    { MonthId: 10, Name: t("october") },
    { MonthId: 11, Name: t("november") },
    { MonthId: 12, Name: t("december") }
]

export const getTranslatedPeriods = (t: TFunction) => [
    { PeriodId: 1, Name: t("monthly") },
    { PeriodId: 2, Name: `2 ${t("monthly1")}` },
    { PeriodId: 3, Name: `3 ${t("monthly1")}` },
    { PeriodId: 4, Name: `4 ${t("monthly1")}` },
    { PeriodId: 5, Name: `5 ${t("monthly1")}` },
    { PeriodId: 6, Name: `6 ${t("monthly1")}` },
    { PeriodId: 7, Name: `7 ${t("monthly1")}` },
    { PeriodId: 8, Name: `8 ${t("monthly1")}` },
    { PeriodId: 9, Name: `9 ${t("monthly1")}` },
    { PeriodId: 10, Name: `10 ${t("monthly1")}` },
    { PeriodId: 11, Name: `11 ${t("monthly1")}` },
    { PeriodId: 12, Name: t("annually") },
    { PeriodId: 13, Name: t("over_years") }
]

// export const months = [
//     { MonthId: 1, Name: t("january") },
//     { MonthId: 2, Name: t("february") },
//     { MonthId: 3, Name: t("march") },
//     { MonthId: 4, Name: t("april") },
//     { MonthId: 5, Name: t("may") },
//     { MonthId: 6, Name: t("june") },
//     { MonthId: 7, Name: t("july") },
//     { MonthId: 8, Name: t("august") },
//     { MonthId: 9, Name: t("september") },
//     { MonthId: 10, Name: t("october") },
//     { MonthId: 11, Name: t("november") },
//     { MonthId: 12, Name: t("december") }
// ]

// export const periods = [
//     { PeriodId: 1, Name: t("monthly") },
//     { PeriodId: 2, Name: `2 ${t("monthly1")}` },
//     { PeriodId: 3, Name: `3 ${t("monthly1")}` },
//     { PeriodId: 4, Name: `4 ${t("monthly1")}` },
//     { PeriodId: 5, Name: `5 ${t("monthly1")}` },
//     { PeriodId: 6, Name: `6 ${t("monthly1")}` },
//     { PeriodId: 7, Name: `7 ${t("monthly1")}` },
//     { PeriodId: 8, Name: `8 ${t("monthly1")}` },
//     { PeriodId: 9, Name: `9 ${t("monthly1")}` },
//     { PeriodId: 10, Name: `10 ${t("monthly1")}` },
//     { PeriodId: 11, Name: `11 ${t("monthly1")}` },
//     { PeriodId: 12, Name: t("annually") },
//     { PeriodId: 13, Name: t("over_years") }
// ]