import axios from 'axios'
import {AuthModel, UserModel, MenuModel, LanguageEnum} from './_models'

const API_URL = process.env.REACT_APP_API_URL

export const GET_USER_BY_ACCESSTOKEN_URL = `${API_URL}/VerifyToken`
export const LOGIN_URL = `${API_URL}/Login`
export const REQUEST_PASSWORD_URL = `${API_URL}/forgot_password`
export const GET_MENU_URL = `${API_URL}/api/Administration/GetMenu`

export async function login(userName: string, password: string, language: LanguageEnum) {
  try {
    const response = await axios
      .post<AuthModel>(LOGIN_URL, {
        userName,
        password,
        language
      })
    return { data: response.data, status: response.status }
  } catch (error: any) {
    return {
      data: undefined,
      status: error.response ? error.response.status : 500,
      error: error.response ? error.response.data : 'Unknown error',
    }
  }
}

export function requestPassword(email: string) {
  return axios.post<{result: boolean}>(REQUEST_PASSWORD_URL, {
    email,
  })
}

export function getUserByToken(token: string) {
  return axios.post<UserModel>(GET_USER_BY_ACCESSTOKEN_URL, {
    ApiToken: token,
  })
}

export function GetMenu(lang: number) {
  return axios.get<MenuModel[]>(GET_MENU_URL, {params: {lang}}).then((response) => {
    return response.data
  })
}
