import { Link } from 'react-router-dom'
import { KTIcon } from '../../../helpers'
import { useEffect, useState } from 'react'
import { RoleManual } from './core/models';
import { downloadManual, fetchManuals } from './core/request';
import { Card4 } from '../../content/cards/Card4';
import DocumentData from './components/DocumentData';
import { t } from 'i18next';

const HelpDrawer = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const [manuals, setManuals] = useState<RoleManual[]>([]);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      await fetchManuals()
        .then((a) => {
          setManuals(a);
        }).catch((e) => console.log(e))
        .finally(() => setLoading(false));

    }

    fetchData();
  }, []);

  const setFileName = {
    "application/pdf": '.pdf',
    "application/vnd.ms-word": '.docx'
  };

  const handleClick = async (data: RoleManual) => {
    const response = await downloadManual(data.ManualId);
    const url = window.URL.createObjectURL(response);
    const link = document.createElement('a');
    link.target = '_blank';
    link.href = url;
    link.setAttribute('download', data.Title + setFileName[response.type]);
    document.body.appendChild(link);
    link.click();
    link.remove();
  }

  return (
    <div
      id='kt_help'
      className='bg-body'
      data-kt-drawer='true'
      data-kt-drawer-name='help'
      data-kt-drawer-activate='true'
      data-kt-drawer-overlay='true'
      data-kt-drawer-width="{default:'350px', 'md': '525px'}"
      data-kt-drawer-direction='end'
      data-kt-drawer-toggle='#kt_help_toggle'
      data-kt-drawer-close='#kt_help_close'
    >
      <div className='card shadow-none rounded-0 w-100'>
        <div className='card-header' id='kt_help_header'>
          <h5 className='card-title fw-bold text-gray-600'>{t('user_manuals')}</h5>
          <div className='card-toolbar'>
            <button
              type='button'
              className='btn btn-sm btn-icon explore-btn-dismiss me-n5'
              id='kt_help_close'
            >
              <KTIcon iconName='cross' className='fs-2' />
            </button>
          </div>
        </div>
        <div className='card-body' id='kt_help_body'>
          <div
            id='kt_help_scroll'
            className='hover-scroll-overlay-y'
            data-kt-scroll='true'
            data-kt-scroll-height='auto'
            data-kt-scroll-wrappers='#kt_help_body'
            data-kt-scroll-dependencies='#kt_help_header'
            data-kt-scroll-offset='5px'
          >
            <div className='d-flex flex-column'>
               {manuals.map((manual) => (
                 <div key={manual.ManualId} className='mb-5'>
                    <DocumentData
                      ManualId={manual.ManualId}
                      DocumentName={manual.Title}
                      Extension={manual.Extension}
                      InsertedDate={manual.InsertedDate}
                      onClick={() => handleClick(manual)}
                    />
                 </div>
               ))}
            </div>
            <div className='d-flex align-items-center mb-7'>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export {HelpDrawer}
