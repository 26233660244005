import {KTIcon} from '../../../helpers'
import {t} from 'i18next'
import {useLang} from '../../../../_metronic/i18n/Metronici18n'
const RequestVideos = () => {
  return (
    <div
      id='kt_videos'
      className='bg-body'
      data-kt-drawer='true'
      data-kt-drawer-name='help'
      data-kt-drawer-activate='true'
      data-kt-drawer-overlay='true'
      data-kt-drawer-width="{default:'350px', 'md': '400px'}"
      data-kt-drawer-direction='end'
      data-kt-drawer-toggle='#request_videos'
      data-kt-drawer-close='#request_videos_close'
    >
      <div className='card shadow-none rounded-0 w-100'>
        <div className='card-header' id='videos_header'>
          <h5 className='card-title fw-bold text-gray-600'>
            {t('how_to_register_a_translation_request')}
          </h5>
          <div className='card-toolbar'>
            <button
              type='button'
              className='btn btn-sm btn-icon explore-btn-dismiss me-n5'
              id='request_videos_close'
            >
              <KTIcon iconName='cross' className='fs-2' />
            </button>
          </div>
        </div>
        <div className='card-body' id='videos_body'>
          <div
            id='kt_videos_scroll'
            className='hover-scroll-overlay-y'
            data-kt-scroll='true'
            data-kt-scroll-height='auto'
            data-kt-scroll-wrappers='#videos_body'
            data-kt-scroll-dependencies='#videos_header'
            data-kt-scroll-offset='5px'
          >
            <div className='d-flex flex-column'>
              {useLang() == 'sq' && (
                <>
                  <div className='d-flex flex-column align-items-center mb-7'>
                    <h6>{t('request_for_translation_into_Albania')}</h6>
                    <video width='100%' height='auto' controls>
                      <source src='/media/requestVideo/request_sq.mp4' type='video/mp4' />
                      Your browser does not support the video tag.
                    </video>
                  </div>
                  <hr />
                </>
              )}
              {useLang() == 'en' && (
                <>
                  <div className='d-flex flex-column align-items-center mb-7'>
                    <h6>{t('request_for_translation_into_English')}</h6>
                    <video width='100%' height='auto' controls>
                      <source src='/media/requestVideo/request_en.mp4' type='video/mp4' />
                      Your browser does not support the video tag.
                    </video>
                  </div>
                  <hr />
                </>
              )}
              {useLang() == 'sr' && (
                <>
                  <div className='d-flex flex-column align-items-center mb-7'>
                    <h6>{t('request_for_translation_into_Serbian')}</h6>
                    <video width='100%' height='auto' controls>
                      <source src='/media/requestVideo/request_sr.mp4' type='video/mp4' />
                      Your browser does not support the video tag.
                    </video>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export {RequestVideos}
