import React, {useEffect, useState} from 'react'
import {useIntl} from 'react-intl'
import {SidebarMenuItemWithSub} from './SidebarMenuItemWithSub'
import {SidebarMenuItem} from './SidebarMenuItem'
import {GetMenu} from '../../../../../app/modules/auth/core/_requests'
import {MenuModel} from '../../../../../app/modules/auth'
import {useLang} from '../../../../../_metronic/i18n/Metronici18n'
interface MenuNode extends MenuModel {
  children: MenuNode[]
}

const SidebarMenuMain = () => {
  const lang = useLang()
  let languageValue = 1
  if (lang === 'sq') {
    languageValue = 1
  } else if (lang === 'en') {
    languageValue = 2
  } else {
    languageValue = 3
  }

  const intl = useIntl()
  const [menuItems, setMenuItems] = useState<MenuNode[]>([])

  useEffect(() => {
    GetMenu(languageValue).then((data: MenuModel[]) => {
      const subMenu = transformMenuItems(data)
      setMenuItems(subMenu)
    })
  }, [])

  const transformMenuItems = (items: MenuModel[]): MenuNode[] => {
    const itemsById: Record<number, MenuNode> = {}
    const child: MenuNode[] = []

    items.forEach((item) => {
      itemsById[item.MenuId] = {...item, children: []}
    })

    items.forEach((item) => {
      if (item.ParentId && itemsById[item.ParentId]) {
        itemsById[item.ParentId].children.push(itemsById[item.MenuId])
      } else {
        child.push(itemsById[item.MenuId])
      }
    })

    Object.values(itemsById).forEach((item) => {
      item.children.sort((a, b) => a.OrderNo - b.OrderNo)
    })

    return child.sort((a, b) => a.OrderNo - b.OrderNo)
  }

  const renderMenuItems = (items: MenuNode[]) => {
    return items
      .map((item) => {
        const subMenus = item.children.filter((child) => child.HasAccess)

        if (item.HasSub && subMenus.length > 0) {
          return (
            <SidebarMenuItemWithSub
              key={item.MenuId}
              to={item.Path}
              title={item.Name}
              icon={item.Icon}
            >
              {renderMenuItems(subMenus)}
            </SidebarMenuItemWithSub>
          )
        } else if (!item.HasSub && item.HasAccess) {
          return (
            <SidebarMenuItem key={item.MenuId} to={item.Path} title={item.Name} icon={item.Icon} />
          )
        }
        return null
      })
      .filter((item) => item !== null)
  }

  return <>{renderMenuItems(menuItems)}</>
}

export {SidebarMenuMain}
