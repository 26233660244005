import {useEffect} from 'react'
import {Outlet} from 'react-router-dom'
import {toAbsoluteUrl} from '../../../_metronic/helpers'
import { useTranslation } from 'react-i18next'

const AuthLayout = () => {
  const {t} = useTranslation()
  useEffect(() => {
    const root = document.getElementById('root')
    if (root) {
      root.style.height = '100%'
    }
    return () => {
      if (root) {
        root.style.height = 'auto'
      }
    }
  }, [])

  return (
    <div className='d-flex flex-column flex-lg-row flex-column-fluid h-100'>
      <div className='d-flex flex-column flex-lg-row-fluid w-100 position-relative'>
        <div
          style={{
            position: 'absolute',
            width: '100%',
            height: '100%',
            backgroundImage: `url(${toAbsoluteUrl('/media/misc/auth-bg.jpg')})`,
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat',
            filter: 'blur(1.4px)',
          }}
        />
        <div className='d-flex flex-column justify-content-start py-15 px-5 px-md-15 w-100 position-relative'>
          <h1 className='text-white fs-2qx fw-bolder text-start mb-7'>
            {t("Centralized_Translation_System")}
          </h1>
          <div className='text-white fs-3 text-start'>
            {t("Centralzed_Translation_System_explain1")}
            <br /> 
            {t("Centralzed_Translation_System_explain2")}
          </div>
        </div>
      </div>
      <div className='d-flex flex-column w-100 w-lg-50 mt-20'>
        <div className='d-flex flex-center flex-column flex-lg-row-fluid'>
          <div className='w-lg-500px p-10'>
            <Outlet />
          </div>
          <div className='fs-base text-gray-500 fw-bold text-center flex-wrap p-10'>
           {t("Translation_System")}
          </div>
        </div>
        <div className='d-flex flex-center flex-wrap p-10'>
          <div className='d-flex fw-semibold fs-base me-4'>
            <span className='fw-bold'>{t("Kosovo_Judicial_Council")} </span> &nbsp;&nbsp;&nbsp; {t("rights_reserved")}
          </div>
          <a href='https://dataprognet-ks.com' target='_blank'>
            <img
              className=''
              src={toAbsoluteUrl('/media/misc/dataprognet.png')}
              width='100'
              height='100'
              alt='DataProgNet Logo'
            />
          </a>
        </div>
      </div>
    </div>
  )
}

export {AuthLayout}
