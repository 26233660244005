import clsx from 'clsx'
import { KTIcon, toAbsoluteUrl } from '../../../helpers'
import { HeaderUserMenu } from '../../../partials'
import { useLayout } from '../../core'
import { HeaderNotificationsMenu } from './header-menus/HeaderNotificationsMenu'
import { Button, Menu, MenuItem } from '@mui/material'
import { useEffect, useState } from 'react'
import Avatar from 'react-avatar';
import { UserData, UserHeaderNotificationVM } from '../../../../../src/app/modules/profile/components/core/_models'
import { getUserData, getUserHeaderNotification } from '../../../../../src/app/modules/profile/components/core/_request'
import { LanguageMenus } from './header-menus/LanguageMenu'
import './NotificationIcon.css';

const itemClass = 'ms-1 ms-md-4'
const userAvatarClass = 'symbol-35px'
const btnClass =
  'btn btn-icon btn-custom btn-icon-gray btn-active-light btn-active-color-primary w-35px h-35px icon-large pulse'
const btnIconClass = 'fs-1'

const Navbar = () => {
  const [userData, setUserData] = useState<UserData | null>(null);
  const [notifications, setNotifications] = useState<UserHeaderNotificationVM[]>([]);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)

  // const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
  //   setAnchorEl(event.currentTarget)
  // } -- Lirim Islami

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const userData = await getUserData();
        setUserData(userData);
      } catch (error) {
        console.error('Error fetching user data:', error);
      }

    };

    const fetchNotifications = async () => {
      try {
        const notifications = await getUserHeaderNotification();
        setNotifications(notifications);
      } catch (error) {
        console.error('Error fetching notifications:', error);
      }
    };



    fetchUserData();
    fetchNotifications();
  }, []);

  // const handleClose = () => {
  //   setAnchorEl(null)
  // } -- Lirim Islami

  const AvatarWithInitials1 = ({ name }) => {
    return (
      <Avatar name={name} round={true} size="40" color='#0056b3' />
    );
  };

  return (
    <div className='app-navbar flex-shrink-0'>
      <div className={clsx('app-navbar-item', itemClass)}>
        <div
          data-kt-menu-trigger="{default: 'click'}"
          data-kt-menu-attach='parent'
          data-kt-menu-placement='bottom-end'
          className={btnClass}
        >
          <KTIcon iconName='notification-on' className={btnIconClass} />
          {/* <span className="pulse-ring"></span>
          <span className="position-absolute top-0 start-0 translate-middle  badge badge-circle badge-primary">5</span> */}
          {notifications.length > 0 && (
            <>
              <span className="pulse-ring"></span>
              <span className="position-absolute top-0 start-0 translate-middle badge badge-circle badge-primary">
                {notifications.length}
              </span>
            </>
          )}
        </div>
        <HeaderNotificationsMenu />
      </div>

      <div className={clsx('app-navbar-item', '')}>
        <div
          data-kt-menu-trigger="{default: 'click'}"
          data-kt-menu-attach='parent'
          data-kt-menu-placement='bottom-end'
        >
          <LanguageMenus />
        </div>
      </div>

      <div className={clsx('app-navbar-item', itemClass)}>
        <div
          className={clsx('cursor-pointer symbol', userAvatarClass)}
          data-kt-menu-trigger="{default: 'click'}"
          data-kt-menu-attach='parent'
          data-kt-menu-placement='bottom-end'
        >
          {/* <img src={toAbsoluteUrl('/media/avatars/300-1.jpg')} alt='' /> */}
          <AvatarWithInitials1 name={userData && `${userData.Firstname} ${userData.Lastname}`} />
        </div>

        <HeaderUserMenu />
      </div>
    </div>
  )
}

export { Navbar }
