import { ReportType } from "../../shared/core/Models";

export interface TranslatorDashboardData {
    TotalDocuments: number;
    UntranslatedDocuments: number;
    TranslatedDocuments: number;
    TotalPages: number;
    TranslatedPages: number;
    UntranslatedPages: number;
    DocumentsByLanguages: TDocumentsByLanguage[];
    DocumentsByStatus: TDocumentsByStatus[];
}

export interface TDocumentsByLanguage {
    Albanian: number;
    English: number;
    Serbian: number;
    Type: string;
}

export interface TDocumentsByStatus {
    Id: number;
    Value: number;
    Label: string;
}

export interface CentralLevelDashboardData {
    TotalDocuments: number;
    UntranslatedDocuments: number;
    TranslatedDocuments: number;
    TotalPages: number;
    TranslatedPages: number;
    Period: string;
    UntranslatedPages: number;
    DocumentsByRegion: CLDocumentsByRegion[];
}

export interface CLDocumentsByRegion {
    Region: string;
    TranslatedDocuments: number;
    UntranslatedDocuments: number;
    UnassignedDocuments: number;
}

export interface RegionsData {
    Id: number;
    Name: string;
    MainRegion: boolean;
}

export interface MonitorDashboardVM
{
    InstitutionName: string;
    City: string;
    Translator: string;
    Requests: string;
    CityPercent: string;
}

export interface SearchPerformance {
    RegionID: number;
    InstitutionID: number;
    Year: number;
    Month: number;
    Period: number;
    From: number;
    To: number;
    ReportType: ReportType;
    Take?: number;
    SortType?: SortType;
}

export enum SortType {
    All = 1,
    PerformanceAscending,
    PerformanceDescending,
    WorkloadAscending,
    WorkloadDescending
}

export interface PerformanceDashboardVM {
    InstitutionId: number;
    RegionId: number;
    Institution: string;
    Performance: number;
    Nfpgj: number;
    Nfpp: number;
    Np: number;
    Kpm: number;
    NOfM: number;
    Workload: string;
    Period: string;
}

export interface InterpreterPerformanceDashboardVM {
    InstitutionId: number;
    RegionId: number;
    UserId: string;
    Interpreter: string;
    Languages: string;
    Performance: number;
    Nfpgj: number;
    Nfpp: number;
    Np: number;
    Kpm: number;
    NOfM: number;
    Workload: string;
    Period: string;
}

export interface ListYearsVM {
    Year: number;
}