import React, {useRef, useEffect, useState} from 'react'
import {KTSVG} from '../../../helpers'
import {Formik, Form, Field} from 'formik'
import * as Yup from 'yup'
import {Button} from 'react-bootstrap'
import {CreateMenu} from '../../../../app/modules/administration/core/_requests'
import Toast from 'react-hot-toast'
import {Modal as BootstrapModal} from 'bootstrap'
import {useIntl} from 'react-intl'

const initialValues = {
  NameSq: '',
  NameEn: '',
  NameSr: '',
  HasSubMenu: false,
  Claim: '',
  Path: '',
  Icon: '',
  Active: true,
  Description: '',
}

const MenuModal = () => {
  const modalRef = useRef<HTMLDivElement>(null)
  const [modal, setBootstrapModal] = useState<BootstrapModal | null>(null)

  useEffect(() => {
    if (modalRef.current) {
      const modal = new BootstrapModal(modalRef.current, {
        backdrop: 'static',
      })
      setBootstrapModal(modal)
    }
  }, [])

  const handleFormSubmit = async (values, {resetForm}) => {
    const result = CreateMenu(values)
    Toast.promise(result, {
      loading: intl.formatMessage({id: 'WAITING'}),
      success: () => {
        return intl.formatMessage({id: 'MENU.CREATED'})
      },
      error: intl.formatMessage({id: 'SYSTEM.ERROR'})
    })

    resetForm()

    if (modal) modal.hide()
  }

  const handleCloseClick = (resetForm) => {
    resetForm({values: initialValues})
    if (modal) modal.hide()
  }

  const intl = useIntl()

  const validationSchema = Yup.object({
    NameSq: Yup.string().required(intl.formatMessage({id: 'FIELD.REQUIRED'})),
    NameEn: Yup.string().required(intl.formatMessage({id: 'FIELD.REQUIRED'})),
    NameSr: Yup.string().required(intl.formatMessage({id: 'FIELD.REQUIRED'})),
    Claim: Yup.string().required(intl.formatMessage({id: 'FIELD.REQUIRED'})),
    HasSubMenu: Yup.boolean().nullable(),
    Path: Yup.string().required(intl.formatMessage({id: 'FIELD.REQUIRED'})),
    Icon: Yup.string().required(intl.formatMessage({id: 'FIELD.REQUIRED'})),
    Active: Yup.boolean(),
    Description: Yup.string().required(intl.formatMessage({id: 'FIELD.REQUIRED'})),
  })

  return (
    <div ref={modalRef} className='modal fade' tabIndex={-1} id='kt_modal_1'>
      <div className='modal-dialog modal-dialog-centered mw-1000px'>
        <div className='modal-content'>
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={handleFormSubmit}
          >
            {({errors, touched, values, resetForm, setFieldValue}) => (
              <Form>
                <div className='modal-header'>
                  <h3 className='modal-title'>{intl.formatMessage({id: 'MENU.ADDMENU'})}</h3>
                  <div
                    className='btn btn-icon btn-sm btn-active-light-primary ms-auto'
                    data-bs-dismiss='modal'
                    aria-label='Close'
                    onClick={() => handleCloseClick(resetForm)}
                  >
                    <KTSVG
                      path='/media/icons/duotune/arrows/arr061.svg'
                      className='svg-icon svg-icon-2x'
                    />
                  </div>
                </div>
                <div className='modal-body'>
                  <div className='row form-group'>
                    <div className='col-lg-4 col-md-6 col-sm-12 mb-3'>
                      <label className='fw-bold required form-label'>
                        {intl.formatMessage({id: 'NAME.SQ'})}
                      </label>
                      <Field
                        as='input'
                        name='NameSq'
                        type='text'
                        className='form-control form-control-sm'
                        placeholder={intl.formatMessage({id: 'NAME.SQ'})}
                      ></Field>
                      {touched.NameSq && errors.NameSq && (
                        <div className='fw-bold text-danger'>{errors.NameSq}</div>
                      )}
                    </div>
                    <div className='col-lg-4 col-md-6 col-sm-12 mb-3'>
                      <label className='fw-bold required form-label'>
                        {intl.formatMessage({id: 'NAME.EN'})}
                      </label>
                      <Field
                        as='input'
                        name='NameEn'
                        type='text'
                        className='form-control form-control-sm'
                        placeholder={intl.formatMessage({id: 'NAME.EN'})}
                      ></Field>
                      {touched.NameEn && errors.NameEn && (
                        <div className='fw-bold text-danger'>{errors.NameEn}</div>
                      )}
                    </div>
                    <div className='col-lg-4 col-md-6 col-sm-12 mb-3'>
                      <label className='fw-bold required form-label'>
                        {intl.formatMessage({id: 'NAME.SR'})}
                      </label>
                      <Field
                        as='input'
                        name='NameSr'
                        type='text'
                        className='form-control form-control-sm'
                        placeholder={intl.formatMessage({id: 'NAME.SR'})}
                      ></Field>
                      {touched.NameSr && errors.NameSr && (
                        <div className='fw-bold text-danger'>{errors.NameSr}</div>
                      )}
                    </div>
                  </div>
                  <div className='row form-group'>
                    <div className='col-lg-4 col-md-6 col-sm-12 mb-3'>
                      <label className='fw-bold required form-label'>
                        {intl.formatMessage({id: 'MENU.CLAIM'})}
                      </label>
                      <Field
                        as='input'
                        name='Claim'
                        type='text'
                        className='form-control form-control-sm'
                        placeholder={intl.formatMessage({id: 'MENU.CLAIM'})}
                      ></Field>
                      {touched.Claim && errors.Claim && (
                        <div className='fw-bold text-danger'>{errors.Claim}</div>
                      )}
                    </div>
                    <div className='col-lg-4 col-md-6 col-sm-12 mb-3'>
                      <label className='fw-bold required form-label'>
                        {intl.formatMessage({id: 'MENU.PATH'})}
                      </label>
                      <Field
                        as='input'
                        name='Path'
                        type='text'
                        className='form-control form-control-sm'
                        placeholder={intl.formatMessage({id: 'MENU.PATH'})}
                      ></Field>
                      {touched.Path && errors.Path && (
                        <div className='fw-bold text-danger'>{errors.Path}</div>
                      )}
                    </div>
                    <div className='col-lg-4 col-md-6 col-sm-12 mb-3'>
                      <label className='fw-bold required form-label'>
                        {intl.formatMessage({id: 'MENU.ICON'})}
                      </label>
                      <Field
                        as='input'
                        name='Icon'
                        type='text'
                        className='form-control form-control-sm'
                        placeholder={intl.formatMessage({id: 'MENU.ICON'})}
                      ></Field>
                      {touched.Icon && errors.Icon && (
                        <div className='fw-bold text-danger'>{errors.Icon}</div>
                      )}
                    </div>
                  </div>
                  <div className='row form-group'>
                    <div className='col-lg-4 col-md-6 col-sm-12 mb-3'>
                      <label className='fw-bold form-label'>
                        {intl.formatMessage({id: 'MENU.ORDER'})}
                      </label>
                      <Field
                        as='input'
                        name='OrderNo'
                        type='text'
                        className='form-control form-control-sm'
                        placeholder={intl.formatMessage({id: 'MENU.ORDER'})}
                      ></Field>
                    </div>
                    <div className='col-lg-4 col-md-6 col-sm-12 mb-3'>
                      <label className='fw-bold required form-label'>
                        {intl.formatMessage({id: 'DESCRIPTION'})}
                      </label>
                      <Field
                        as='input'
                        name='Description'
                        type='text'
                        className='form-control form-control-sm'
                        placeholder={intl.formatMessage({id: 'DESCRIPTION'})}
                      ></Field>
                      {touched.Description && errors.Description && (
                        <div className='fw-bold text-danger'>{errors.Description}</div>
                      )}
                    </div>
                    <div className='col-md-4 form-group mb-5 mt-lg-10'>
                      <label className='form-check form-switch form-check-custom form-check-solid'>
                        <Field
                          name='Active'
                          className='form-check-input'
                          type='checkbox'
                          checked={values.Active}
                        />
                        <span className='form-label fw-bold ms-2'>
                          {intl.formatMessage({id: 'MENU.ACTIVE'})}
                        </span>
                      </label>
                    </div>
                  </div>
                  <div className='row form-group'>
                    <div className='col-md-4 form-group mb-5 mt-lg-10'>
                      <label className='form-check form-switch form-check-custom form-check-solid'>
                        <Field
                          name='HasSubMenu'
                          type='checkbox'
                          className='form-check-input'
                          checked={values.HasSubMenu}
                          onChange={() => setFieldValue('HasSubMenu', !values.HasSubMenu)}
                        />
                        <span className='form-label fw-bold ms-2'>
                          {intl.formatMessage({id: 'MENU.HASSUBMENU'})}
                        </span>
                      </label>
                    </div>
                  </div>
                </div>
                <div className='d-flex justify-content-between modal-footer'>
                  <Button
                    variant='light'
                    className='fw-bold btn btn-sm'
                    data-bs-dismiss='modal'
                    onClick={() => handleCloseClick(resetForm)}
                  >
                    {intl.formatMessage({id: 'CANCEL'})}
                  </Button>
                  <Button variant='primary' type='submit' className='fw-bold btn btn-sm'>
                    {intl.formatMessage({id: 'CREATE'})}
                  </Button>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </div>
  )
}

export {MenuModal}
