import { useEffect, useState } from "react";
import { InterpreterPerformanceDashboardVM, ListYearsVM, RegionsData, SearchPerformance, SortType } from "../../core/Models";
import moment from "moment";
import { CentralLevelTranslatorDashboard, ListYears, RegionsRequest, ReportInterpreterPerformance } from "../../core/RequestsAPI";
import { Backdrop, CircularProgress, MenuItem, Select, SelectChangeEvent, TextField } from "@mui/material";
import { Form, Formik } from "formik";
import { getTranslatedMonths, getTranslatedPeriods } from "../../../../constants/date.constants";
import { Button as ReactButton } from "react-bootstrap";
import ProgressWidget from "../ProgressWidget";
import { KTSVG } from "../../../../../_metronic/helpers";
import { useTranslation } from "react-i18next";
import PrintDropDown from "../PrintDropDown";
import { ReportType } from "../../../shared/core/Models";

const TranslatorTab: React.FC = () => {
    const { t } = useTranslation();

    const months = getTranslatedMonths(t);
    const periods = getTranslatedPeriods(t);

    const [loading, setLoading] = useState<boolean>(false);

    const [dashboardData, setDashboardData] = useState<InterpreterPerformanceDashboardVM[]>();
    const [displayedData, setDisplayedData] = useState<InterpreterPerformanceDashboardVM[]>();

    const [cities, setCities] = useState<RegionsData[]>();
    const [selectedMainId, setSelectedMainId] = useState<number | null>(null);
    const [selectedCityId, setSelectedCityId] = useState<number | null>(0);

    const [selectedValue, setSelectedValue] = useState(selectedCityId !== null ? `${selectedCityId}-${selectedMainId ? 'true' : 'false'}` : '');

    const [selectedSort, setSelectedSort] = useState(5);
    const [selectedFilter, setSelectedFilter] = useState(0);

    const [years, setYears] = useState<ListYearsVM[] | null>();
    const [ascYears, setAscYears] = useState<ListYearsVM[] | null>();

    const [selectedPeriod, setSelectedPeriod] = useState<string>('');
    const [searchTerm, setSearchTerm] = useState<string>('');

    const initialValues: SearchPerformance = {
        RegionID: 0,
        InstitutionID: 0,
        Year: moment().year(),
        Month: moment().month() + 1,
        Period: 1,
        From: 0,
        To: 0,
        ReportType: ReportType.PDF,
        Take: 5,
        SortType: SortType.All
    }
    const [updatedValues, setUpdatedValues] = useState<SearchPerformance>(initialValues);

    const sortNumbers = (data: ListYearsVM[], direction: "asc" | "desc"): ListYearsVM[] => {
        return data.slice().sort((a, b) => { return direction === "desc" ? b.Year - a.Year : a.Year - b.Year; });
    }

    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);
            await CentralLevelTranslatorDashboard(updatedValues)
                .then((a) => {
                    setDashboardData(a);

                    let filteredData = [...a];
                    setDisplayedData(filterData(selectedFilter, filteredData));
                    setDisplayedData(filteredData.slice(0, selectedSort));
                    setSelectedPeriod(a ? (a[0] !== undefined && a[0] !== null ? a[0].Period : '') : '');
                }).catch((error) => {
                    console.error("There has been an error while fetching data for dashboard:", error);
                }).finally(() => {
                    setLoading(false);
                });

            await RegionsRequest()
                .then((a) => {
                    setCities(a);
                }).catch((error) => {
                    console.error("There has been an error while fetching regions for dashboard:", error);
                });

            await ListYears()
                .then((a) => {
                    setAscYears(sortNumbers(a, "asc"));
                    setYears(sortNumbers(a, "desc"));
                })
                .catch((error) => console.log(error));
        }

        fetchData();
    }, []);

    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);
            await CentralLevelTranslatorDashboard(updatedValues)
                .then((a) => {
                    setDashboardData(a);

                    let filteredData = [...a];
                    setDisplayedData(filterData(selectedFilter, filteredData));
                    setDisplayedData(filteredData.slice(0, selectedSort));
                    setSelectedPeriod(a ? (a[0] !== undefined && a[0] !== null ? a[0].Period : '') : '');
                }).catch((error) => {
                    console.error("There has been an error while fetching data for dashboard:", error);
                }).finally(() => {
                    setLoading(false);
                });
        }

        if (updatedValues.Period !== 13) {
            fetchData();
        }
    }, [updatedValues]);

    useEffect(() => {
        if (dashboardData) {
            let filteredData = [...dashboardData];

            filteredData = filterData(selectedFilter, filteredData);

            setDisplayedData(filteredData.slice(0, selectedSort));
        }

    }, [updatedValues, selectedSort, selectedFilter]);

    const filterData = (
        selectedFilter: number,
        filteredData: InterpreterPerformanceDashboardVM[]
    ): InterpreterPerformanceDashboardVM[] => {
        if (selectedFilter === 0) {
            return filteredData;
        }

        switch (selectedFilter) {
            case 1:
                filteredData.sort((a, b) => { return b.Performance - a.Performance });
                return filteredData;
            case 2:
                filteredData.sort((a, b) => { return a.Performance - b.Performance });
                return filteredData;
            case 3:
                filteredData.sort((a, b) => {
                    const [requestA, pagesA] = a.Workload.split('/');
                    const [requestB, pagesB] = b.Workload.split('/');

                    return parseInt(pagesB, 10) - parseInt(pagesA, 10);
                });
                return filteredData;
            case 4:
                filteredData.sort((a, b) => {
                    const [requestA, pagesA] = a.Workload.split('/');
                    const [requestB, pagesB] = b.Workload.split('/');

                    return parseInt(pagesA, 10) - parseInt(pagesB, 10);
                });
                return filteredData;
            default:
                return filteredData;
        }
    }

    const handlePeriodSubmit = async () => {
        setLoading(true);
        await CentralLevelTranslatorDashboard(updatedValues)
            .then((a) => {
                setDashboardData(a);

                let filteredData = [...a];
                setDisplayedData(filterData(selectedFilter, filteredData));
                setDisplayedData(filteredData.slice(0, selectedSort));
                setSelectedPeriod(a ? (a[0] !== undefined && a[0] !== null ? a[0].Period : '') : '');
            }).catch((error) => {
                console.error("There has been an error while fetching data for dashboard:", error);
            }).finally(() => {
                setLoading(false);
            });
    };

    const handleSubmit = async (cityId: number, type: 'main' | 'other') => {
        setLoading(true);
        if (cityId === 0) {
            setSelectedCityId(cityId);
            setSelectedMainId(null);
            await CentralLevelTranslatorDashboard(updatedValues)
                .then((a) => {
                    setDashboardData(a);

                    let filteredData = [...a];
                    setDisplayedData(filterData(selectedFilter, filteredData));
                    setDisplayedData(filteredData.slice(0, selectedSort));
                    setSelectedPeriod(a ? (a[0] !== undefined && a[0] !== null ? a[0].Period : '') : '');
                }).catch((error) => {
                    console.error("There has been an error while fetching data for dashboard:", error);
                }).finally(() => {
                    setLoading(false);
                });
        } else {
            if (type === 'main') {
                setSelectedMainId(cityId);
                setSelectedCityId(null);
                const data: SearchPerformance = {
                    ...updatedValues,
                    RegionID: 0,
                    InstitutionID: cityId
                }

                await CentralLevelTranslatorDashboard(data)
                    .then((a) => {
                        setDashboardData(a);

                        let filteredData = [...a];
                        setDisplayedData(filterData(selectedFilter, filteredData));
                        setDisplayedData(filteredData.slice(0, selectedSort));
                        setSelectedPeriod(a ? (a[0] !== undefined && a[0] !== null ? a[0].Period : '') : '');
                    }).catch((error) => {
                        console.error("There has been an error while fetching data for dashboard:", error);
                    }).finally(() => {
                        setLoading(false);
                    });
            } else {
                setSelectedCityId(cityId);
                setSelectedMainId(null);
                const data: SearchPerformance = {
                    ...updatedValues,
                    RegionID: cityId,
                    InstitutionID: 0
                }

                await CentralLevelTranslatorDashboard(data)
                    .then((a) => {
                        setDashboardData(a);

                        let filteredData = [...a];
                        setDisplayedData(filterData(selectedFilter, filteredData));
                        setDisplayedData(filteredData.slice(0, selectedSort));
                        setSelectedPeriod(a ? (a[0] !== undefined && a[0] !== null ? a[0].Period : '') : '');
                    }).catch((error) => {
                        console.error("There has been an error while fetching data for dashboard:", error);
                    }).finally(() => {
                        setLoading(false);
                    });
            }
        }
    };

    const handleBackdropClose = () => setLoading(false);
    if (!dashboardData || !displayedData) {
        return <Backdrop
                    sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                    open={loading}
                    onClick={handleBackdropClose}
                >
                    <CircularProgress color="inherit"/>
                </Backdrop>;
    }

    const searchDashboardData = displayedData.filter(data =>
        data.Interpreter.toLowerCase().includes(searchTerm.toLowerCase())
    );

    const handleReport = async (reportType: ReportType) => {
        setLoading(true);

        const data: SearchPerformance = {
            ... updatedValues,
            ReportType: reportType
        }

        const response = await ReportInterpreterPerformance(data);
        const url = window.URL.createObjectURL(response);
        const link = document.createElement('a');
        link.target = '_blank';
        link.href = url;
        link.setAttribute('download', t("translator_performance_report"));
        document.body.appendChild(link);
        link.click();
        link.remove();

        setLoading(false);
    }

    const handleChange = (event: SelectChangeEvent) => {
        setSearchTerm('');
        const [cityId, mainRegion] = event.target.value.split('-');
        setSelectedValue(event.target.value);
        handleSubmit(Number(cityId), mainRegion === 'true' ? 'main' : 'other');
    }

    const sortOptions = [
        { value: 5, option: 5 },
        { value: 10, option: 10 },
        { value: 20, option: 20 },
        { value: 50, option: 50 },
        { value: 100, option: 100 }
    ]

    const filterCategory = [
        { value: 0, option: t("all") },
        { value: 1, option: t("performance_high") },
        { value: 2, option: t("performance_low") },
        { value: 3, option: t("workload_high") },
        { value: 4, option: t("workload_low") }
    ]

    const handleSortChange = (event: SelectChangeEvent) => {
        let take = parseInt(event.target.value, 10);
        setSelectedSort(take);
        setUpdatedValues({
            ...updatedValues,
            Take: take
        });
    };

    const handleFilterChange = (event: SelectChangeEvent) => {
        let sortType = parseInt(event.target.value, 10);
        setSelectedFilter(sortType);
        setUpdatedValues({
            ...updatedValues,
            SortType: sortType + 1
        });
    };

    return (
        <>
            <div className="card card-xl-stretch mb-xl-8">
                <div className="card-body d-flex align-items-center justify-content-between py-5">
                    <div className="row" style={{ width: '100%' }}>
                        <div className="col-sm-12 col-md-3">
                            <div className="form-group">
                                <label className="form-label fw-semibold">{t("regions")}</label>
                                <Select
                                    id="CitySelect"
                                    name="CitySelect"
                                    size="small"
                                    displayEmpty={true}
                                    fullWidth={true}
                                    style={{ fontFamily: "Inter, Helvetica, sans-serif", width: '100%' }}
                                    inputProps={{ 'aria-label': 'Without label' }}
                                    value={selectedValue}
                                    onChange={handleChange}
                                >
                                    <MenuItem
                                        style={{ fontFamily: "Inter, Helvetica, sans-serif" }}
                                        value={`0-false`}
                                        selected={selectedValue === "0-false"}
                                    >
                                        {t("all")}
                                    </MenuItem>
                                    {cities && cities.map(city => (
                                        <MenuItem
                                            style={{ fontFamily: "Inter, Helvetica, sans-serif" }}
                                            key={`${city.Id}-${city.MainRegion}`}
                                            value={`${city.Id}-${city.MainRegion}`}
                                            selected={selectedValue === `${city.Id}-${city.MainRegion}`}
                                            // selected={city.MainRegion ? (selectedMainId === city.Id) : (selectedCityId === city.Id)}
                                        >
                                            {city.Name}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </div>
                        </div>
                    </div>
                    <div className="card-toolbar" style={{ width: '50%' }}>
                        <Formik
                            initialValues={initialValues}
                            onSubmit={handlePeriodSubmit}
                        >
                            {(formProps) => (
                                <Form style={{ width: '100%' }}>
                                    <div className="row">
                                        <div className={`col-sm-12 ${updatedValues.Period === 13 ? "col-md-3" : "col-md-4"}`}>
                                            <label className="form-label">{t("period")}</label>
                                            <Select
                                                id="Period"
                                                name="Period"
                                                size="small"
                                                displayEmpty={true}
                                                fullWidth={true}
                                                className="demo-select-small-label"
                                                style={{ fontFamily: "Inter, Helvetica, sans-serif" }}
                                                inputProps={{ 'aria-label': 'Without label' }}
                                                onBlur={formProps.handleBlur}
                                                value={updatedValues.Period !== null ? String(updatedValues.Period) : ''}
                                                onChange={(event: SelectChangeEvent) => {
                                                    setUpdatedValues({
                                                        ...updatedValues,
                                                        Period: event.target.value === "" ? 1 : Number(event.target.value)
                                                    });
                                                }}
                                            >
                                                {periods.map((period) => (
                                                    <MenuItem
                                                        style={{ fontFamily: "Inter, Helvetica, sans-serif" }}
                                                        key={period.PeriodId}
                                                        value={period.PeriodId}
                                                        selected={period.PeriodId === 1}
                                                    >{period.Name}</MenuItem>
                                                ))}
                                            </Select>
                                        </div>
                                        {updatedValues.Period === 13 ? (
                                            <>
                                                <div className="col-sm-12 col-md-3">
                                                    <label className="form-label">{t("from")}</label>
                                                    <Select
                                                        id="From"
                                                        name="From"
                                                        size="small"
                                                        displayEmpty={true}
                                                        fullWidth={true}
                                                        className="demo-select-small-label"
                                                        style={{ fontFamily: "Inter, Helvetica, sans-serif" }}
                                                        inputProps={{ 'aria-label': 'Without label' }}
                                                        onBlur={formProps.handleBlur}
                                                        value={updatedValues.From !== null ? String(updatedValues.From) : ''}
                                                        onChange={(event: SelectChangeEvent) => {
                                                            setUpdatedValues({
                                                                ...updatedValues,
                                                                From: event.target.value === "" ? (moment().year()) : Number(event.target.value)
                                                            });
                                                        }}
                                                    >
                                                        {ascYears?.map((year) => (
                                                            <MenuItem
                                                                style={{ fontFamily: "Inter, Helvetica, sans-serif" }}
                                                                key={year.Year}
                                                                value={year.Year}
                                                            >{year.Year}</MenuItem>
                                                        ))}
                                                    </Select>
                                                </div>
                                                <div className="col-sm-12 col-md-3">
                                                    <label className="form-label">{t("to")}</label>
                                                    <Select
                                                        id="To"
                                                        name="To"
                                                        size="small"
                                                        displayEmpty={true}
                                                        fullWidth={true}
                                                        className="demo-select-small-label"
                                                        style={{ fontFamily: "Inter, Helvetica, sans-serif" }}
                                                        inputProps={{ 'aria-label': 'Without label' }}
                                                        onBlur={formProps.handleBlur}
                                                        value={updatedValues.To !== null ? String(updatedValues.To) : ''}
                                                        onChange={(event: SelectChangeEvent) => {
                                                            setUpdatedValues({
                                                                ...updatedValues,
                                                                To: event.target.value === "" ? (moment().year() + 1) : Number(event.target.value)
                                                            });
                                                        }}
                                                    >
                                                        {years?.map((year) => (
                                                            <MenuItem
                                                                style={{ fontFamily: "Inter, Helvetica, sans-serif" }}
                                                                key={year.Year}
                                                                value={year.Year}
                                                            >{year.Year}</MenuItem>
                                                        ))}
                                                    </Select>
                                                </div>
                                                <div className="col-sm-12 col-md-3">
                                                    <label className="form-label invisible">{t('until')}</label>
                                                    <ReactButton
                                                        variant='primary'
                                                        type="submit"
                                                        className='fw-bold btn btn-sm btn-block'
                                                        style={{ width: '100%' }}
                                                    >{t("search")}</ReactButton>
                                                </div>
                                            </>
                                        ) : (
                                            <>
                                                <div className="col-sm-12 col-md-4">
                                                    <label className="form-label">{t("year")}</label>
                                                    <Select
                                                        id="Year"
                                                        name="Year"
                                                        size="small"
                                                        displayEmpty={true}
                                                        fullWidth={true}
                                                        className="demo-select-small-label"
                                                        style={{ fontFamily: "Inter, Helvetica, sans-serif" }}
                                                        inputProps={{ 'aria-label': 'Without label' }}
                                                        onBlur={formProps.handleBlur}
                                                        value={updatedValues.Year !== null ? String(updatedValues.Year) : ''}
                                                        onChange={(event: SelectChangeEvent) => {
                                                            setUpdatedValues({
                                                                ...updatedValues,
                                                                Year: event.target.value === "" ? (moment().year()) : Number(event.target.value)
                                                            });
                                                        }}
                                                    >
                                                        {years?.map((year) => (
                                                            <MenuItem
                                                                style={{ fontFamily: "Inter, Helvetica, sans-serif" }}
                                                                key={year.Year}
                                                                value={year.Year}
                                                                selected={year.Year === (moment().year())}
                                                            >{year.Year}</MenuItem>
                                                        ))}
                                                    </Select>
                                                </div>
                                                <div className="col-sm-12 col-md-4">
                                                    <label className="form-label">{t("month")}</label>
                                                    <Select
                                                        id="Month"
                                                        name="Month"
                                                        size="small"
                                                        displayEmpty={true}
                                                        fullWidth={true}
                                                        className="demo-select-small-label"
                                                        style={{ fontFamily: "Inter, Helvetica, sans-serif" }}
                                                        inputProps={{ 'aria-label': 'Without label' }}
                                                        onBlur={formProps.handleBlur}
                                                        value={updatedValues.Month !== null ? String(updatedValues.Month) : ''}
                                                        onChange={(event: SelectChangeEvent) => {
                                                            setUpdatedValues({
                                                                ...updatedValues,
                                                                Month: event.target.value === "" ? (moment().month() + 1) : Number(event.target.value)
                                                            });
                                                        }}
                                                    >
                                                        {months.map((month) => (
                                                            <MenuItem
                                                                style={{ fontFamily: "Inter, Helvetica, sans-serif" }}
                                                                key={month.MonthId}
                                                                value={month.MonthId}
                                                                selected={month.MonthId === (moment().month() + 1)}
                                                            >{month.Name}</MenuItem>
                                                        ))}
                                                    </Select>
                                                </div>
                                            </>
                                        )}
                                    </div>
                                </Form>
                            )}
                        </Formik>
                    </div>
                </div>
                {/* <div className="card-body p-9">
                    <div className="d-flex justify-content-start align-items-center" style={{ overflowX: 'auto', width: '100%', whiteSpace: 'nowrap' }}>
                        <ReactButton
                            id="0"
                            role="button"
                            tabIndex={-1}
                            variant={selectedCityId === 0 ? "primary" : "secondary"}
                            style={{ marginRight: '1.25rem' }}
                            onClick={() => handleSubmit(0, 'other')}
                        >{t("all")}</ReactButton>
                        {cities && (cities.map(city => (
                            <ReactButton
                                key={city.Id}
                                role="button"
                                tabIndex={-1}
                                id={city.Id.toString()}
                                variant={(city.MainRegion ? (selectedMainId === city.Id) : (selectedCityId === city.Id)) ? "primary" : "secondary"}
                                style={{ marginRight: '1.25rem' }}
                                onClick={() => handleSubmit(city.Id, city.MainRegion ? 'main' : 'other')}
                            >{city.Name}</ReactButton>)))}
                    </div>
                </div> */}
            </div>
            <div className="card card-xl-stretch mb-xl-8">
                <div className="card-header d-flex flex-stack pt-5 pb-3">
                    <h3 className="card-title align-items-start flex-column">
                        <span className="card-label fw-bold fs-3 mb-1">
                            {t("translator_performance")} {selectedPeriod ? `(${selectedPeriod})` : ""}
                        </span>
                    </h3>
                    <div className="card-toolbar d-inline-flex justify-content-end align-items-center" style={{ width: '30%' }}>
                        <PrintDropDown align='end' onClick={handleReport}/>
                        {/* <ReactButton
                            variant='primary'
                            className='fw-bold btn btn-sm btn-block ms-3'
                            onClick={handleReport}
                        >{t("print")}</ReactButton> */}
                    </div>
                </div>
                <div className="card-body">
                    <div className="d-flex align-items-center justify-content-between pb-5">
                        <div className="row" style={{ width: '100%' }}>
                            <div className="col-sm-12 col-md-2">
                                <label className="form-label">{t("see")}</label>
                                <Select
                                    id="Sort"
                                    name="Sort"
                                    size="small"
                                    displayEmpty={true}
                                    fullWidth={true}
                                    className="demo-select-small-label"
                                    style={{ fontFamily: "Inter, Helvetica, sans-serif", width: '100%' }}
                                    inputProps={{ 'aria-label': 'Without label' }}
                                    value={selectedSort.toString()}
                                    onChange={handleSortChange}
                                >
                                    {sortOptions.map((option) => (
                                        <MenuItem
                                            style={{ fontFamily: "Inter, Helvetica, sans-serif" }}
                                            key={option.value}
                                            value={option.value}
                                            selected={option.value === selectedSort}
                                        >{option.option}</MenuItem>
                                    ))}
                                </Select>
                            </div>
                            <div className="col-sm-12 col-md-3">
                                <label className="form-label">{t("filter_by_category")}</label>
                                <Select
                                    id="Filter"
                                    name="Filter"
                                    size="small"
                                    displayEmpty={true}
                                    fullWidth={true}
                                    className="demo-select-small-label"
                                    style={{ fontFamily: "Inter, Helvetica, sans-serif", width: '100%' }}
                                    inputProps={{ 'aria-label': 'Without label' }}
                                    value={selectedFilter.toString()}
                                    onChange={handleFilterChange}
                                >
                                    {filterCategory.map((option) => (
                                        <MenuItem
                                            style={{ fontFamily: "Inter, Helvetica, sans-serif" }}
                                            key={option.value}
                                            value={option.value}
                                            selected={option.value === selectedFilter}
                                        >{option.option}</MenuItem>
                                    ))}
                                </Select>
                            </div>
                        </div>
                        <div className="form-group">
                            <label className="form-label">{t("search")}</label>
                            <TextField
                                size='small'
                                className='col-md-4'
                                type="text"
                                id="tf_search"
                                value={searchTerm}
                                onChange={(e) => setSearchTerm(e.target.value)}
                                style={{ fontFamily: 'Inter, Helvetica, sans-serif', width: '100%' }}
                            />
                        </div>
                    </div>
                    {searchDashboardData.length > 0 ? (
                        <div className="row gy-5 gy-xl-8">
                            {searchDashboardData.map((data) => (
                                <div className="col-sm-12 col-md-3 col-lg-2 col-xl-2">
                                    <ProgressWidget
                                        key={data.UserId}
                                        chartColor='dark'
                                        chartHeight='150px'
                                        nfpgj={data.Nfpgj}
                                        nfpp={data.Nfpp}
                                        np={data.Np}
                                        kpm={data.Kpm}
                                        nofm={data.NOfM}
                                        total={data.Performance}
                                        court={false}
                                        name={data.Interpreter}
                                        subName={data.Languages}
                                        percentage={data.Performance}
                                        workload={data.Workload}
                                        isActive={false}
                                        maxWidth="250px"
                                    />
                                </div>
                            ))}
                        </div>
                    ) : (
                        <div className="d-flex align-items-center justify-content-start">
                            <div className="notice d-flex bg-light-primary rounded border-primary border p-6 w-100 d-flex align-items-center">
                                <KTSVG path="media/icons/duotune/general/gen044.svg" className="svg-icon-2hx svg-icon-primary me-3" />
                                <div className="d-flex flex-stack flex-grow-1">
                                    <div className="fw-bold" style={{ width: '80%' }}>
                                        <h4 className="text-gray-800 fw-bolder">{t("no_results_found")}</h4>
                                        <div className="fs-6 text-gray-600">{t("no_translator_for_region")}</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            </div>
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={loading}
                onClick={handleBackdropClose}
            >
                <CircularProgress color="inherit"/>
            </Backdrop>
        </>
    )
}

export default TranslatorTab;

const buttonStyle = {
    fontFamily: 'Inter, Helvetica, sans-serif',
    fontSize: '1rem',
    width: '100%',
    marginTop: 0,
    marginRight: '1.25rem'
}