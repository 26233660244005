import React, { Component } from 'react';
import clsx from 'clsx';
import { changeLanguage, useLang } from '../../../../../_metronic/i18n/Metronici18n';
import { KTIcon } from 'src/_metronic/helpers';
import { withTranslation } from 'react-i18next';

type Props = {
  toggleBtnClass?: string;
  menuPlacement?: string;
  menuTrigger?: string;
  t: (key: string) => string;
};

const languageDetails = {
  sq: { name: 'Shqip' },
  en: { name: 'Anglisht' },
  sr: { name: 'Serbisht' }
};

class LanguageMenu extends Component<Props> {
  static defaultProps = {
    toggleBtnClass: 'btn-active-light-primary w-30px h-30px w-md-40px h-md-40px'
  };

  state = {
    currentLanguage: useLang()
  };

  handleLanguageChange = (language: string) => {
    changeLanguage(language);
    this.setState({ currentLanguage: language });
  };

  render() {
    const { toggleBtnClass, t } = this.props;
    const { currentLanguage } = this.state;

    return (
      <>
        <div className='d-flex align-items-center ms-1 ms-lg-3'>
          <a className={clsx('btn btn-icon', toggleBtnClass)}>
            {/* <KTIcon iconName='gear' className='fs-1' /> */}
            <i className="bi bi-translate fs-1"></i>
          </a>
          <div
            className='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-title-gray-700 menu-icon-muted menu-active-bg menu-state-primary fw-semibold py-4 fs-base w-150px'
            data-kt-menu='true'
          >
            {Object.entries(languageDetails).map(([code, details]) => (
              <div className='menu-item px-3 my-0' key={code}>
                <a className={clsx('menu-link py-2', { 'active': currentLanguage === code })} onClick={() => this.handleLanguageChange(code)}>
                  <span className='menu-title'>{details.name}</span>
                </a>
              </div>
            ))}
          </div>
        </div>
      </>
    );
  }
}

export const LanguageMenus = withTranslation()(LanguageMenu);