/* eslint-disable react/jsx-no-target-blank */
import {useEffect} from 'react'
import {ILayout, useLayout} from '../../core'
import {toAbsoluteUrl} from '../../../../_metronic/helpers'

const Footer = () => {
  const {config} = useLayout()
  useEffect(() => {
    updateDOM(config)
  }, [config])
  return (
    <>
      <div className='text-dark'>
        <span className='fw-bold'>© Këshilli Gjyqësor i Kosovës 2024.</span> Të gjitha të drejtat e rezervuara.
      </div>
      <a href='https://dataprognet-ks.com' target='_blank' >
        <img
          className=''
          src={toAbsoluteUrl('/media/misc/dataprognet.png')}
          width='100'
          height='100'
          alt='DataProgNet Logo'
        />
      </a>
    </>
  )
}

const updateDOM = (config: ILayout) => {
  if (config.app?.footer?.fixed?.desktop) {
    document.body.classList.add('data-kt-app-footer-fixed', 'true')
  }

  if (config.app?.footer?.fixed?.mobile) {
    document.body.classList.add('data-kt-app-footer-fixed-mobile', 'true')
  }
}

export {Footer}
