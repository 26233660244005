import ApiService from "../../../services/ApiService";
import {
    CentralLevelDashboardData,
    MonitorDashboardVM,
    PerformanceDashboardVM,
    RegionsData,
    SearchPerformance,
    TranslatorDashboardData,
    InterpreterPerformanceDashboardVM,
    ListYearsVM
} from "./Models";

export async function TranslatorDashboardRequest(): Promise<TranslatorDashboardData | null> {
    return ApiService.fetchData({
        url: `/api/Home/TranslatorDashboard`,
        method: "get"
    });
}

export async function CentralLevelDashboardRequest(data: SearchPerformance): Promise<CentralLevelDashboardData | null> {
    return ApiService.fetchData({
        url: "/api/Home/CentralLevelGeneralDashboard",
        method: "post",
        data
    });
}

export async function InstitutionDashboardRequest(data: SearchPerformance): Promise<CentralLevelDashboardData | null> {
    return ApiService.fetchData({
        url: "/api/Home/InstitutionDashboard",
        method: "post",
        data
    });
}

export async function RegionsRequest(): Promise<RegionsData[]> {
    return ApiService.fetchData({
        url: `/api/Home/Regions`,
        method: "get"
    });
}

export async function MonitorDashboardRequest(): Promise<MonitorDashboardVM[]> {
    return ApiService.fetchData({
        url: `/api/Home/MonitorDashboard`,
        method: "get"
    });
}

export async function PerformanceDashboardRequest(data: SearchPerformance): Promise<PerformanceDashboardVM[] | null> {
    return ApiService.fetchData({
        url: "/api/Home/PerformanceDashboard",
        method: "post",
        data
    });
}

export async function InterpreterPerformanceDashboardRequest(data: SearchPerformance): Promise<InterpreterPerformanceDashboardVM[] | null> {
    return ApiService.fetchData({
        url: "/api/Home/InterpreterPerformanceDashboard",
        method: "post",
        data
    });
}

export async function CentralLevelTranslatorDashboard(data: SearchPerformance): Promise<InterpreterPerformanceDashboardVM[]> {
    return ApiService.fetchData({
        url: "/api/Home/CentralLevelTranslatorDashboard",
        method: "post",
        data
    });
}

export async function ListYears(): Promise<ListYearsVM[]> {
    return ApiService.fetchData({
        url: `/api/Home/ListYears`,
        method: "get"
    });
}

export async function ReportInterpreterPerformance(data: SearchPerformance): Promise<any> {
    return ApiService.fetchData({
        url: "/api/Home/InterpreterPerformanceReport",
        method: "post",
        data,
        responseType: 'blob'
    });
}

export async function PerformanceRegionReport(data: SearchPerformance): Promise<any> {
    return ApiService.fetchData({
        url: "/api/Home/PerformanceRegionReport",
        method: "post",
        data,
        responseType: 'blob'
    });
}

export async function PerformanceCourtReport(data: SearchPerformance): Promise<any> {
    return ApiService.fetchData({
        url: "/api/Home/PerformanceCourtReport",
        method: "post",
        data,
        responseType: 'blob'
    });
}

export async function PerformanceInterpreterReport(data: SearchPerformance): Promise<any> {
    return ApiService.fetchData({
        url: "/api/Home/PerformanceInterpreterReport",
        method: "post",
        data,
        responseType: 'blob'
    });
}

// export async function ReportInterpreterPerformance(search: SearchPerformance): Promise<any> {
//     try {
//         const response = await axios.post(INTERPRETER_PERFORMANCE_REPORT, search, { responseType: 'blob' });
//         return response.data;
//     } catch (error) {
//         console.error("There has been an error while fetching data, ReportInterpreterPerformance:", error);
//         return null;
//     }
// }

// export async function PerformanceRegionReport(search: SearchPerformance): Promise<any> {
//     try {
//         const response = await axios.post(PERFORMANCE_REGION_REPORT, search, { responseType: 'blob' });
//         return response.data;
//     } catch (error) {
//         console.error("There has been an error while fetching data, PerformanceRegionReport:", error);
//         return null;
//     }
// }

// export async function PerformanceCourtReport(search: SearchPerformance): Promise<any> {
//     try {
//         const response = await axios.post(PERFORMANCE_COURT_REPORT, search, { responseType: 'blob' });
//         return response.data;
//     } catch (error) {
//         console.error("There has been an error while fetching data, PerformanceCourtReport:", error);
//         return null;
//     }
// }

// export async function PerformanceInterpreterReport(search: SearchPerformance): Promise<any> {
//     try {
//         const response = await axios.post(PERFORMANCE_INTERPRETER_REPORT, search, { responseType: 'blob' });
//         return response.data;
//     } catch (error) {
//         console.error("There has been an error while fetching data, PerformanceInterpreterReport:", error);
//         return null;
//     }
// }