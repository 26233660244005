import * as React from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { Box } from '@mui/material';
import { useTranslation } from 'react-i18next';

import PerformanceTab from '../components/CentralLevelTabs/PerformanceTab';
import GeneralTab from '../components/CentralLevelTabs/GeneralTab';
import TranslatorTab from '../components/CentralLevelTabs/TranslatorTab';

function a11yProps(index: number) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

export default function CentralLevel() {
    const { t } = useTranslation();

    const [value, setValue] = React.useState(0);

    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        setValue(newValue);
    };

    return (
        <Box sx={{ width: '100%', marginTop: 2 }}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
                    <Tab label={t("performance")} {...a11yProps(0)} sx={menuTitle} />
                    <Tab label={t("translators")} {...a11yProps(1)} sx={menuTitle} />
                    <Tab label={t("general")} {...a11yProps(2)} sx={menuTitle} />
                </Tabs>
            </Box>
            <RenderTabPanel value={value} index={0}>
                <PerformanceTab />
            </RenderTabPanel>
            <RenderTabPanel value={value} index={1}>
                <TranslatorTab />
            </RenderTabPanel>
            <RenderTabPanel value={value} index={2}>
                <GeneralTab />
            </RenderTabPanel>
        </Box>
    );
}

interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
}

function RenderTabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role='tabpanel'
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ paddingTop: 3, paddingBottom: 3 }}>
                    {children}
                </Box>
            )}
        </div>
    );
}

const menuTitle = {
    fontWeight: '600',
    fontSize: '1.1rem',
    fontFamily: 'Inter, Helvetica, sans-serif'
}