import axios from 'axios'
import {getAuth} from '../modules/auth'
import {REQUEST_HEADER_AUTH_KEY, TOKEN_TYPE} from '../constants/api.constants'

const unauthorizedCode = [403]
const API_URL = process.env.REACT_APP_API_URL

const BaseService = axios.create({
  baseURL: API_URL,
})

BaseService.interceptors.request.use(
  (config) => {
    const authData = getAuth()

    if (authData?.api_token) {
      config.headers[REQUEST_HEADER_AUTH_KEY] = `${TOKEN_TYPE}${authData.api_token}`
    }

    return config
  },
  (error) => {
    return Promise.reject(error)
  }
)

BaseService.interceptors.response.use(
  (response) => response,
  (error) => {
    const {response} = error
    const authData = getAuth()

    if (unauthorizedCode.includes(response.status)) {
      if (authData?.api_token !== '') {
        const baseURL = window.location.origin
        const targetURL = new URL('/error/403', baseURL)
        window.location.replace(targetURL.toString())
        return new Promise(() => {})
      }
    } else {
      const baseURL = window.location.origin
      const targetURL = new URL('/login', baseURL)
      window.location.replace(targetURL.toString())
      return new Promise(() => {})
    }
    return Promise.reject(error)
  }
)

export default BaseService
