import React, {FC, createContext, useContext, ReactNode, useEffect, useState} from 'react'
import i18next from 'i18next'
import global_sq from '../../translations/sq/global.json'
import global_en from '../../translations/en/global.json'
import global_sr from '../../translations/sr/global.json'
import {initReactI18next} from 'react-i18next'
import axios from 'axios'
const API_URL = process.env.REACT_APP_API_URL
const CHANGE_LANGUAGE = `${API_URL}/api/Home/ChangeLanguage`
const I18N_CONFIG_KEY = process.env.REACT_APP_I18N_CONFIG_KEY || 'i18nConfig'
const AUTH_LOCAL_STORAGE_KEY = 'kt-auth-react-v'

type Props = {
  selectedLang: 'sq' | 'en' | 'sr'
}

const initialState: Props = {
  selectedLang: 'sq',
}

function getConfig(): Props {
  const authLocalStorage = localStorage.getItem(AUTH_LOCAL_STORAGE_KEY)
  if (authLocalStorage) {
    const data = JSON.parse(authLocalStorage)
    try {
      if (typeof data.language === 'string') {
        const config = {selectedLang: data.language} as Props
        if (['sq', 'en', 'sr'].includes(config.selectedLang)) {
          return config
        } else {
          changeLanguage(initialState.selectedLang)
        }
      } else {
        const config = data.language as Props
        if (['sq', 'en', 'sr'].includes(config.selectedLang)) {
          return config
        } else {
          changeLanguage(initialState.selectedLang)
        }
      }
      // const config = JSON.parse(data.language) as Props
      // if (['sq', 'en', 'sr'].includes(config.selectedLang)) {
      //   return config
      // } else {
      //   changeLanguage(initialState.selectedLang)
      // }
    } catch (er) {
      console.error(er)
    }
  }
  return initialState
}

const I18nContext = createContext<Props>(getConfig())

const useLang = () => {
  return getConfig().selectedLang
  //return useContext(I18nContext).selectedLang
}

type WithChildren = {
  children?: ReactNode
}

export const changeLanguage = async (newLang: string) => {
  switch (newLang) {
    case 'sq':
    case 'en':
    case 'sr':
      localStorage.setItem(I18N_CONFIG_KEY, JSON.stringify({selectedLang: newLang}))
      i18next.changeLanguage(newLang)
      const authLocalStorage = localStorage.getItem(AUTH_LOCAL_STORAGE_KEY)
      if (authLocalStorage) {
        const data = JSON.parse(authLocalStorage)
        try {
          if (typeof data.language === 'string') {
            data.language = newLang
            localStorage.setItem(AUTH_LOCAL_STORAGE_KEY, JSON.stringify(data))
          } else {
            data.language.selectedLang = newLang
            localStorage.setItem(AUTH_LOCAL_STORAGE_KEY, JSON.stringify(data))
          }
        } catch (er) {
          console.error(er)
        }
      }
      const response = await axios.post(`${CHANGE_LANGUAGE}/${newLang}`)
      window.location.reload()
      break
    default:
      localStorage.setItem(I18N_CONFIG_KEY, JSON.stringify({selectedLang: 'sq'}))
      i18next.changeLanguage('sq')
      window.location.reload()
      break
  }
}

const MetronicI18nProvider: FC<WithChildren> = ({children}) => {
  const [lang, setLang] = useState<Props>(getConfig())

  useEffect(() => {
    i18next.use(initReactI18next).init({
      interpolation: {escapeValue: false},
      lng: lang.selectedLang,
      resources: {
        en: {
          translation: global_en,
        },
        sq: {
          translation: global_sq,
        },
        sr: {
          translation: global_sr,
        },
      },
    })
  }, [lang.selectedLang])

  return <I18nContext.Provider value={lang}>{children}</I18nContext.Provider>
}

export {MetronicI18nProvider, useLang}
