import {useState} from 'react'
import {useTranslation} from 'react-i18next'

interface DescriptionDataProps {
  description: string
  length: number
}

const DescriptionData: React.FC<DescriptionDataProps> = ({description, length}) => {
  const {t} = useTranslation()
  const [showMore, setShowMore] = useState<boolean>(false)

  const toggleShowMore = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault()
    e.stopPropagation()
    setShowMore(!showMore)
  }

  if (description === null || description === undefined) {
    return <p className='mb-0'>///</p>
  } else if (description.length === 0) {
    return <p className='mb-0'>///</p>
  }

  if (description.length <= length) {
    return <p className='mb-0'>{description}</p>
  }

  return (
    <p className='mb-0'>
      {showMore ? description : `${description.substring(0, length)}...`}
      <span
        className='ms-2 text-hover-primary'
        style={{cursor: 'pointer'}}
        onClick={toggleShowMore}
      >
        {showMore ? t('see_less') : t('see_more')}
      </span>
    </p>
  )
}

export default DescriptionData
