import Dropdown from 'react-bootstrap/Dropdown';
import { useTranslation } from 'react-i18next';
import { ReportType } from '../../shared/core/Models';
import { AlignType } from 'react-bootstrap/esm/types';

interface PrintDropDownProps {
    align?: AlignType;
    onClick: (type: ReportType) => void;
}

const PrintDropDown: React.FC<PrintDropDownProps> = ({
    align,
    onClick
}) => {
    const { t } = useTranslation();

    const logoMap: { [key: string]: string } = {
        'pdf': require('../../../../_metronic/media/svg/files/pdf.svg').default,
        'doc': require('../../../../_metronic/media/svg/files/doc.svg').default,
        'xlsx': require('../../../../_metronic/media/svg/files/xls.png')
    };

    return (
        <Dropdown align={align ? align : 'end'}>
            <Dropdown.Toggle id="dropdown-basic">{t("print")}</Dropdown.Toggle>
            <Dropdown.Menu>
                <Dropdown.Item as="button" onClick={() => onClick(ReportType.PDF)}>
                    <PrintButton logo={logoMap["pdf"]} text='PDF'/>
                </Dropdown.Item>
                <Dropdown.Item as="button" onClick={() => onClick(ReportType.Word)}>
                    <PrintButton logo={logoMap["doc"]} text='Word'/>
                </Dropdown.Item>
                <Dropdown.Item as="button" onClick={() => onClick(ReportType.Excel)}>
                    <PrintButton logo={logoMap["xlsx"]} text='Excel'/>
                </Dropdown.Item>
            </Dropdown.Menu>
        </Dropdown>
    )
}

export default PrintDropDown;

interface PrintButtonProps {
    logo: string;
    text: string;
}

const PrintButton: React.FC<PrintButtonProps> = ({
    logo,
    text
}) => (
    <div className='row d-flex align-items-center'>
        <div className='col-4'>
            <img src={logo} alt={text} style={{ width: '30px', alignSelf: 'center' }}/>
        </div>
        <div className='col-8'>
            <span className='fw-semibold'>{text}</span>
        </div>
    </div>
)