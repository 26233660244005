import { useEffect, useRef, useState } from "react";
import moment from "moment";
import { KTSVG } from "../../../../../_metronic/helpers";
import { CentralLevelDashboardData, ListYearsVM, RegionsData, SearchPerformance } from "../../core/Models";
import { CentralLevelDashboardRequest, InstitutionDashboardRequest, ListYears, RegionsRequest } from "../../core/RequestsAPI";
import { Backdrop, CircularProgress, Select, SelectChangeEvent, MenuItem } from "@mui/material";
import StatsCard from "../../components/StatsCard";
import { BarChart } from "@mui/x-charts/BarChart";
import { axisClasses } from '@mui/x-charts/ChartsAxis';
import { Form, Formik } from "formik";
import { getTranslatedMonths, getTranslatedPeriods } from "../../../../constants/date.constants";
import { Button as ReactButton } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { ReportType } from "../../../shared/core/Models";

const GeneralTab: React.FC = () => {
    const { t } = useTranslation();

    const months = getTranslatedMonths(t);
    const periods = getTranslatedPeriods(t);

    const [loading, setLoading] = useState<boolean>(false);
    const [dashboardData, setDashboardData] = useState<CentralLevelDashboardData | null>();

    const [cities, setCities] = useState<RegionsData[] | null>();
    const [selectedMainId, setSelectedMainId] = useState<number | null>(null);
    const [selectedCityId, setSelectedCityId] = useState<number | null>(0);

    const [selectedValue, setSelectedValue] = useState(selectedCityId !== null ? `${selectedCityId}-${selectedMainId ? 'true' : 'false'}` : '');

    const [years, setYears] = useState<ListYearsVM[] | null>();
    const [ascYears, setAscYears] = useState<ListYearsVM[] | null>();

    const [selectedPeriod, setSelectedPeriod] = useState<string>('');

    const initialValues: SearchPerformance = {
        RegionID: 0,
        InstitutionID: 0,
        Year: moment().year(),
        Month: moment().month() + 1,
        Period: 1,
        From: 0,
        To: 0,
        ReportType: ReportType.PDF
    }
    const [updatedValues, setUpdatedValues] = useState<SearchPerformance>(initialValues);

    const sortNumbers = (data: ListYearsVM[], direction: "asc" | "desc"): ListYearsVM[] => {
        return data.slice().sort((a, b) => { return direction === "desc" ? b.Year - a.Year : a.Year - b.Year; });
    }

    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);
            await CentralLevelDashboardRequest(updatedValues)
                .then((a) => {
                    setDashboardData(a);
                    setSelectedPeriod(a ? a.Period : '');
                }).catch((error) => {
                    console.error("There has been an error while fetching data for dashboard:", error);
                }).finally(() => {
                    setLoading(false);
                });

            await RegionsRequest()
                .then((a) => {
                    setCities(a);
                }).catch((error) => {
                    console.error("There has been an error while fetching regions for dashboard:", error);
                });

            await ListYears()
                .then((a) => {
                    setAscYears(sortNumbers(a, "asc"));
                    setYears(sortNumbers(a, "desc"));
                })
                .catch((error) => console.log(error));
        }

        fetchData();
    }, []);

    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);
            if (selectedCityId != null && selectedCityId != undefined) {
                if (selectedCityId === 0) {
                    await CentralLevelDashboardRequest(updatedValues)
                        .then((a) => {
                            setDashboardData(a);
                            setSelectedPeriod(a ? a.Period : '');
                        }).catch((error) => {
                            console.error("There has been an error while fetching data for dashboard:", error);
                        }).finally(() => {
                            setLoading(false);
                        });
                } else {
                    const data: SearchPerformance = {
                        ...updatedValues,
                        RegionID: selectedCityId
                    }
                    await InstitutionDashboardRequest(data)
                        .then((a) => {
                            setDashboardData(a);
                            setSelectedPeriod(a ? a.Period : '');
                        }).catch((error) => {
                            console.error("There has been an error while fetching data for dashboard:", error);
                        }).finally(() => {
                            setLoading(false);
                        });
                }
            }
        }

        if (updatedValues.Period !== 13) {
            fetchData();
        }
    }, [updatedValues]);

    const [parentWidth, setParentWidth] = useState<number>(0);
    const parentRef = useRef<HTMLDivElement>(null);
    useEffect(() => {
        const updateParentWidth = () => {
            if (parentRef.current) {
                const width = parentRef.current.offsetWidth;
                setParentWidth(width);
            }
        };

        updateParentWidth();
        window.addEventListener('resize', updateParentWidth);
        return () => window.removeEventListener('resize', updateParentWidth);
    }, []);

    const handleSubmit = async (cityId: number, type: 'main' | 'other') => {
        setLoading(true);
        if (cityId === 0) {
            setSelectedCityId(cityId);
            setSelectedMainId(null);
            await CentralLevelDashboardRequest(updatedValues)
                .then((a) => {
                    setDashboardData(a);
                }).catch((error) => {
                    console.error("There has been an error while fetching data for dashboard:", error);
                }).finally(() => {
                    setLoading(false);
                });
        } else {
            if (type === 'main') {
                setSelectedMainId(cityId);
                setSelectedCityId(null);
                const data: SearchPerformance = {
                    ...updatedValues,
                    RegionID: 0,
                    InstitutionID: cityId
                }

                await InstitutionDashboardRequest(data)
                    .then((a) => {
                        setDashboardData(a);
                    }).catch((error) => {
                        console.error("There has been an error while fetching data for dashboard:", error);
                    }).finally(() => {
                        setLoading(false);
                    });
            } else {
                setSelectedCityId(cityId);
                setSelectedMainId(null);
                const data: SearchPerformance = {
                    ...updatedValues,
                    RegionID: cityId,
                    InstitutionID: 0
                }

                await InstitutionDashboardRequest(data)
                    .then((a) => {
                        setDashboardData(a);
                    }).catch((error) => {
                        console.error("There has been an error while fetching data for dashboard:", error);
                    }).finally(() => {
                        setLoading(false);
                    });
            }
        }
    };

    const handleClose = () => setLoading(false);

    if (!dashboardData) {
        return <Backdrop
                    sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                    open={loading}
                    onClick={handleClose}
                >
                    <CircularProgress color="inherit"/>
                </Backdrop>;
    }

    const regions = dashboardData.DocumentsByRegion.map(item => item.Region);
    const translatedDocuments = dashboardData.DocumentsByRegion.map(item => item.TranslatedDocuments);
    const untranslatedDocuments = dashboardData.DocumentsByRegion.map(item => item.UntranslatedDocuments);
    const unassignedDocuments = dashboardData.DocumentsByRegion.map(item => item.UnassignedDocuments);

    const seriesTranslated = {
        data: translatedDocuments,
        label: t("number_translated_documents"),
        stack: 'stack',
    };

    const seriesUntranslated = {
        data: untranslatedDocuments,
        label: t("number_untranslated_documents"),
        stack: 'stack',
    };

    const seriesUnassigned = {
        data: unassignedDocuments,
        label: t("number_unassigned_documents"),
        stack: 'stack',
    };

    const handleChange = (event: SelectChangeEvent) => {
        const [cityId, mainRegion] = event.target.value.split('-');
        setSelectedValue(event.target.value);
        handleSubmit(Number(cityId), mainRegion === 'true' ? 'main' : 'other');
    }

    const handlePeriodSubmit = async () => {
        setLoading(true);
        if (selectedCityId != null && selectedCityId != undefined) {
            if (selectedCityId === 0) {
                await CentralLevelDashboardRequest(updatedValues)
                    .then((a) => {
                        setDashboardData(a);
                        setSelectedPeriod(a ? a.Period : '');
                    }).catch((error) => {
                        console.error("There has been an error while fetching data for dashboard:", error);
                    }).finally(() => {
                        setLoading(false);
                    });
            } else {
                const data: SearchPerformance = {
                    ...updatedValues,
                    RegionID: selectedCityId
                }
                await InstitutionDashboardRequest(data)
                    .then((a) => {
                        setDashboardData(a);
                        setSelectedPeriod(a ? a.Period : '');
                    }).catch((error) => {
                        console.error("There has been an error while fetching data for dashboard:", error);
                    }).finally(() => {
                        setLoading(false);
                    });
            }
        }
    }

    return (
        <>
            <div className="card card-xl-stretch mb-xl-8">
                <div className="card-body d-flex align-items-center justify-content-between py-5">
                <div className="row" style={{ width: '100%' }}>
                        <div className="col-sm-12 col-md-3">
                            <div className="form-group">
                                <label className="form-label fw-semibold">{t("regions")}</label>
                                <Select
                                    id="CitySelect"
                                    name="CitySelect"
                                    size="small"
                                    displayEmpty={true}
                                    fullWidth={true}
                                    style={{ fontFamily: "Inter, Helvetica, sans-serif", width: '100%' }}
                                    inputProps={{ 'aria-label': 'Without label' }}
                                    value={selectedValue}
                                    onChange={handleChange}
                                >
                                    <MenuItem
                                        style={{ fontFamily: "Inter, Helvetica, sans-serif" }}
                                        value={`0-false`}
                                        selected={selectedValue === "0-false"}
                                    >
                                        {t("all")}
                                    </MenuItem>
                                    {cities && cities.map(city => (
                                        <MenuItem
                                            style={{ fontFamily: "Inter, Helvetica, sans-serif" }}
                                            key={`${city.Id}-${city.MainRegion}`}
                                            value={`${city.Id}-${city.MainRegion}`}
                                            selected={selectedValue === `${city.Id}-${city.MainRegion}`}
                                            // selected={city.MainRegion ? (selectedMainId === city.Id) : (selectedCityId === city.Id)}
                                        >
                                            {city.Name}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </div>
                        </div>
                    </div>
                    <div className="card-toolbar" style={{ width: '50%' }}>
                        <Formik
                            initialValues={initialValues}
                            onSubmit={handlePeriodSubmit}
                        >
                            {(formProps) => (
                                <Form style={{ width: '100%' }}>
                                    <div className="row">
                                        <div className={`col-sm-12 ${updatedValues.Period === 13 ? "col-md-3" : "col-md-4"}`}>
                                            <label className="form-label">{t("period")}</label>
                                            <Select
                                                id="Period"
                                                name="Period"
                                                size="small"
                                                displayEmpty={true}
                                                fullWidth={true}
                                                className="demo-select-small-label"
                                                style={{ fontFamily: "Inter, Helvetica, sans-serif" }}
                                                inputProps={{ 'aria-label': 'Without label' }}
                                                onBlur={formProps.handleBlur}
                                                value={updatedValues.Period !== null ? String(updatedValues.Period) : ''}
                                                onChange={(event: SelectChangeEvent) => {
                                                    setUpdatedValues({
                                                        ...updatedValues,
                                                        Period: event.target.value === "" ? 1 : Number(event.target.value)
                                                    });
                                                }}
                                            >
                                                {periods.map((period) => (
                                                    <MenuItem
                                                        style={{ fontFamily: "Inter, Helvetica, sans-serif" }}
                                                        key={period.PeriodId}
                                                        value={period.PeriodId}
                                                        selected={period.PeriodId === 1}
                                                    >{period.Name}</MenuItem>
                                                ))}
                                            </Select>
                                        </div>
                                        {updatedValues.Period === 13 ? (
                                            <>
                                                <div className="col-sm-12 col-md-3">
                                                    <label className="form-label">{t("from")}</label>
                                                    <Select
                                                        id="From"
                                                        name="From"
                                                        size="small"
                                                        displayEmpty={true}
                                                        fullWidth={true}
                                                        className="demo-select-small-label"
                                                        style={{ fontFamily: "Inter, Helvetica, sans-serif" }}
                                                        inputProps={{ 'aria-label': 'Without label' }}
                                                        onBlur={formProps.handleBlur}
                                                        value={updatedValues.From !== null ? String(updatedValues.From) : ''}
                                                        onChange={(event: SelectChangeEvent) => {
                                                            setUpdatedValues({
                                                                ...updatedValues,
                                                                From: event.target.value === "" ? (moment().year()) : Number(event.target.value)
                                                            });
                                                        }}
                                                    >
                                                        {ascYears?.map((year) => (
                                                            <MenuItem
                                                                style={{ fontFamily: "Inter, Helvetica, sans-serif" }}
                                                                key={year.Year}
                                                                value={year.Year}
                                                            >{year.Year}</MenuItem>
                                                        ))}
                                                    </Select>
                                                </div>
                                                <div className="col-sm-12 col-md-3">
                                                    <label className="form-label">{t("to")}</label>
                                                    <Select
                                                        id="To"
                                                        name="To"
                                                        size="small"
                                                        displayEmpty={true}
                                                        fullWidth={true}
                                                        className="demo-select-small-label"
                                                        style={{ fontFamily: "Inter, Helvetica, sans-serif" }}
                                                        inputProps={{ 'aria-label': 'Without label' }}
                                                        onBlur={formProps.handleBlur}
                                                        value={updatedValues.To !== null ? String(updatedValues.To) : ''}
                                                        onChange={(event: SelectChangeEvent) => {
                                                            setUpdatedValues({
                                                                ...updatedValues,
                                                                To: event.target.value === "" ? (moment().year() + 1) : Number(event.target.value)
                                                            });
                                                        }}
                                                    >
                                                        {years?.map((year) => (
                                                            <MenuItem
                                                                style={{ fontFamily: "Inter, Helvetica, sans-serif" }}
                                                                key={year.Year}
                                                                value={year.Year}
                                                            >{year.Year}</MenuItem>
                                                        ))}
                                                    </Select>
                                                </div>
                                                <div className="col-sm-12 col-md-3">
                                                    <label className="form-label invisible">Deri</label>
                                                    <ReactButton
                                                        variant='primary'
                                                        type="submit"
                                                        className='fw-bold btn btn-sm btn-block'
                                                        style={{ width: '100%' }}
                                                    >{t("search")}</ReactButton>
                                                </div>
                                            </>
                                        ) : (
                                            <>
                                                <div className="col-sm-12 col-md-4">
                                                    <label className="form-label">{t("year")}</label>
                                                    <Select
                                                        id="Year"
                                                        name="Year"
                                                        size="small"
                                                        displayEmpty={true}
                                                        fullWidth={true}
                                                        className="demo-select-small-label"
                                                        style={{ fontFamily: "Inter, Helvetica, sans-serif" }}
                                                        inputProps={{ 'aria-label': 'Without label' }}
                                                        onBlur={formProps.handleBlur}
                                                        value={updatedValues.Year !== null ? String(updatedValues.Year) : ''}
                                                        onChange={(event: SelectChangeEvent) => {
                                                            setUpdatedValues({
                                                                ...updatedValues,
                                                                Year: event.target.value === "" ? (moment().year()) : Number(event.target.value)
                                                            });
                                                        }}
                                                    >
                                                        {years?.map((year) => (
                                                            <MenuItem
                                                                style={{ fontFamily: "Inter, Helvetica, sans-serif" }}
                                                                key={year.Year}
                                                                value={year.Year}
                                                                selected={year.Year === (moment().year())}
                                                            >{year.Year}</MenuItem>
                                                        ))}
                                                    </Select>
                                                </div>
                                                <div className="col-sm-12 col-md-4">
                                                    <label className="form-label">{t("month")}</label>
                                                    <Select
                                                        id="Month"
                                                        name="Month"
                                                        size="small"
                                                        displayEmpty={true}
                                                        fullWidth={true}
                                                        className="demo-select-small-label"
                                                        style={{ fontFamily: "Inter, Helvetica, sans-serif" }}
                                                        inputProps={{ 'aria-label': 'Without label' }}
                                                        onBlur={formProps.handleBlur}
                                                        value={updatedValues.Month !== null ? String(updatedValues.Month) : ''}
                                                        onChange={(event: SelectChangeEvent) => {
                                                            setUpdatedValues({
                                                                ...updatedValues,
                                                                Month: event.target.value === "" ? (moment().month() + 1) : Number(event.target.value)
                                                            });
                                                        }}
                                                    >
                                                        {months.map((month) => (
                                                            <MenuItem
                                                                style={{ fontFamily: "Inter, Helvetica, sans-serif" }}
                                                                key={month.MonthId}
                                                                value={month.MonthId}
                                                                selected={month.MonthId === (moment().month() + 1)}
                                                            >{month.Name}</MenuItem>
                                                        ))}
                                                    </Select>
                                                </div>
                                            </>
                                        )}
                                    </div>
                                </Form>
                            )}
                        </Formik>
                    </div>
                </div>
            </div>
            <div className="row mb-5 mb-xl-8 gy-5 gy-xl-8">
                <div className="col-xl-3">
                    <StatsCard
                        color="info"
                        icon={<KTSVG path="media/icons/duotune/files/fil006.svg" className="svg-icon-white svg-icon-2hx" />}
                        text={t("total_number_to_translate")}
                        stat={dashboardData?.UntranslatedDocuments || 0}
                        total={dashboardData?.TotalDocuments}
                        loading={loading}
                    />
                </div>
                <div className="col-xl-3">
                    <StatsCard
                        color="primary"
                        icon={<KTSVG path="media/icons/duotune/files/fil008.svg" className="svg-icon-white svg-icon-2hx" />}
                        text={t("total_number_translated")}
                        stat={dashboardData?.TranslatedDocuments || 0}
                        total={dashboardData?.TotalDocuments}
                        loading={loading}
                    />
                </div>
                <div className="col-xl-3">
                    <StatsCard
                        color="secondary"
                        icon={<KTSVG path="media/icons/duotune/files/fil008.svg" className="svg-icon-dark svg-icon-2hx" />}
                        text={t("total_untranslated_pages")}
                        stat={dashboardData?.UntranslatedPages || 0}
                        total={dashboardData?.TotalPages}
                        loading={loading}
                    />
                </div>
                <div className="col-xl-3">
                    <StatsCard
                        color="dark"
                        icon={<KTSVG path="media/icons/duotune/files/fil008.svg" className="svg-icon-white svg-icon-2hx" />}
                        text={t("total_translated_pages")}
                        stat={dashboardData?.TranslatedPages || 0}
                        total={dashboardData?.TotalPages}
                        loading={loading}
                    />
                </div>
            </div>
            <div className="row g-5 g-xl-8">
                <div className="col-xl-12">
                    <div className="card card-xl-stretch mb-xl-8">
                        <div className="card-header pt-5 pb-3">
                            <h3 className="card-title align-items-start flex-column">
                                <span className="card-label fw-bold fs-3 mb-1">{t("number_documents_by_region")}</span>
                                <span className="text-muted fw-semibold fs-7">{t("translated_untranslated")}</span>
                            </h3>
                        </div>
                        <div className="card-body" ref={parentRef}>
                            <BarChart
                                series={[seriesUntranslated, seriesTranslated, seriesUnassigned]}
                                layout="vertical"
                                xAxis={[{
                                    scaleType: 'band',
                                    data: regions,
                                    tickLabelStyle: {
                                        angle: -20,
                                        textAnchor: 'end',
                                        fontSize: 12,
                                        fontFamily: 'Inter, Helvetica, sans-serif',
                                        fontWeight: 500
                                    }
                                }]}
                                yAxis={[
                                    {
                                      valueFormatter: (value) => `${(value / 1).toLocaleString()}`,
                                      label: t("number_total_documents"),
                                    }
                                ]}
                                slotProps={{
                                    legend: {
                                        direction: 'row',
                                        position: { vertical: 'top', horizontal: 'left' },
                                        padding: 0,
                                        itemMarkWidth: 40,
                                        markGap: 10,
                                        labelStyle: {
                                            fontFamily: 'Inter, Helvetica, sans-serif'
                                        }
                                    }
                                }}
                                height={450}
                                colors={['#7239ea', '#009ef7', '#dbdfe9']}
                                grid={{ horizontal: true, vertical: true }}
                                margin={{ top: 50, right: 5, bottom: 125, left: 100 }}
                                sx={{
                                    [`.${axisClasses.left} .${axisClasses.label}`]: {
                                        transform: 'translateX(-20px)',
                                        fontFamily: 'Inter, Helvetica, sans-serif',
                                        fontWeight: 500
                                    },
                                }}
                            />
                        </div>
                    </div>
                </div>
            </div>
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={loading}
                onClick={handleClose}
            >
                <CircularProgress color="inherit"/>
            </Backdrop>
        </>
    )
}

export default GeneralTab;

const buttonStyle = {
    fontFamily: 'Inter, Helvetica, sans-serif',
    fontSize: '1rem',
    width: '100%',
    marginTop: 0,
    marginRight: '1.25rem'
}