import {  NotificationVM, RequestCount, SearchPerformance, TranslatorPerformance, TranslatorPerformanceVM, UserAccessProfile, UserData, UserHeaderNotificationVM, UserMonthlyPerformanceParams, UserProfileData, UserRequestsDoneForTranslationCount, UserRequestsForTranslationCount } from "./_models";
import ApiService from "../../../../../app/services/ApiService";

export async function getUserData(): Promise<UserData | null> {
    return await ApiService.fetchData({
        url: `/api/UserProfile/GetUserData`,
        method: "get"
    })
}

export async function getUserCreatedRequestsNumber(): Promise<RequestCount> {
    return await ApiService.fetchData({
        url: `/api/UserProfile/GetUserRequestNumber`,
        method: "get"
    })
}

export async function getUserNumberForRequestsForTranslation(): Promise<UserRequestsForTranslationCount> {
    return await ApiService.fetchData({
        url: `/api/UserProfile/GetUserNumberForRequestsForTranslation`,
        method: "get"
    })

}

export async function getUserRequestsForTrnaslationDoneCount(): Promise<UserRequestsDoneForTranslationCount> {
    return await ApiService.fetchData({
        url: `/api/UserProfile/GetUserDoneRequestsCount`,
        method: "get"
    })
}

export async function getPerformanceData(params: UserMonthlyPerformanceParams): Promise<TranslatorPerformance[]> {
    return await ApiService.fetchData({
        url: `/api/UserProfile/UserMonthlyPerformance`,
        method: "get",
        params: params
    })

}

export async function getUserAccessAsRole(): Promise<UserAccessProfile[]> {
    return await ApiService.fetchData({
        url: `/api/UserProfile/GetUserAccessAsRole`,
        method: "get"

    })

}

export async function getUserProfileData(): Promise<UserProfileData> {
    return await ApiService.fetchData({
        url: `/api/UserProfile/ProfileData`,
        method: "get"
    })

}

export async function getUserNotifications(): Promise<NotificationVM[]> {
    return await ApiService.fetchData({
        url: `/api/UserProfile/Notifications`,
        method: "get"
    })
}

export async function markNotificationAsRead(notificationId: number): Promise<void> {
    const data = { NotificationId: notificationId }
    return await ApiService.fetchData({
        url: `/api/UserProfile/NotificatonStatusUpdate`,
        method: "put",
        data
    })

}

export async function getUserHeaderNotification(): Promise<UserHeaderNotificationVM[]> {
    return await ApiService.fetchData({
        url: `/api/UserProfile/UserHeaderNotification`,
        method: "get"
    })
}

export async function getUserActiveRole(): Promise<boolean> {
    return await ApiService.fetchData({
        url: `/api/UserProfile/GetUserActiveRole`,
        method: "get"

    })

}

export async function getTranslatorPerformance(data: SearchPerformance): Promise<TranslatorPerformanceVM[]> {
    return await ApiService.fetchData({
        url: `/api/UserProfile/UserPerformanceDashboard`,
        method: "post",
        data
    })
}