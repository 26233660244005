import { Box, Tooltip, Typography } from '@mui/material';
import Zoom from '@mui/material/Zoom';
import { styled } from '@mui/material/styles';
import React from 'react';
import { useTranslation } from 'react-i18next';

interface TooltipProps {
    NFPGJ: string;
    NFPP: string;
    NP: string;
    KPM: string;
    NOfM: string;
    Total: string;
    Court: boolean;
    children: React.ReactNode
}

const HTMLTooltip = styled(({ className, ...props }: any) => (
    <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
    [`& .MuiTooltip-tooltip`]: {
        backgroundColor: '#fff',
        color: '#000',
        maxWidth: 500,
        fontSize: theme.typography.pxToRem(18),
        border: '1px solid #dadde9',
        padding: '10px'
    }
}));

const CustomTooltip: React.FC<TooltipProps> = ({
    NFPGJ,
    NFPP,
    NP,
    KPM,
    NOfM,
    Total,
    Court,
    children
}) => {
    const { t } = useTranslation();

    const title = Court ? t("translation_efficiency_court") : t("translation_efficiency_translator");
    const OfTitle = Court ? t("efficiency_court") : t("efficiency_translator");
    const noPagesTitle = Court ? t("ptc") : t("ptt");
    const noPages = Court ? t("number_pages_translated_court") : t("number_pages_translated_translator");
    const coefficientMonthly = t("coefficient_monthly_rate");

    return (
        <HTMLTooltip
            TransitionComponent={Zoom}
            arrow
            title={
                <Box>
                    <Typography variant='body2' component="p" sx={titleStyle}>{title}</Typography>
                    <Typography variant='body2' component="p" sx={formulaStyle}>
                        <div className='d-flex align-items-center justify-content-start fw-bolder'>
                            <span>{OfTitle} =</span>
                            <div className='d-flex align-items-center justify-content-center flex-column mx-2'>
                                <span className='pb-1'>N<sub>{noPagesTitle}</sub></span>
                                <span style={{ borderTop: "1px solid #000" }}>
                                    {Court && (<span className='me-2'><span className='me-2'>N<sub>{t("t_translator")}</sub></span>×</span>)}<span className='me-2'>{t("cpm_coefficient")}<sub>{t("cpm_per_month")}</sub></span>
                                </span>
                            </div>
                            <span>× 100%</span>
                        </div>
                    </Typography>
                    <Typography variant='body2' component="p" sx={parameterStyle}>
                        <b>N<sub>{Court ? t("ptc") : t("ptt")}</sub></b> - {noPages}
                    </Typography>
                    {Court && (
                        <Typography variant='body2' component="p" sx={parameterStyle}>
                            <b>N<sub>p</sub></b> - {t("number_translators_court")}
                        </Typography>
                    )}
                    <Typography variant='body2' component="p" sx={parameterStyle}>
                        <b>k<sub>pm</sub></b> - {coefficientMonthly}
                    </Typography>
                    {/* <Typography variant='body2' component="p" sx={parameterStyle}>
                        <b>N<sub>m</sub></b> - {t("monthly_search_period")}
                    </Typography> */}
                    <Typography variant='body2' component="p" sx={calculatorStyle}>
                        <div className='d-flex align-items-center justify-content-start fw-bolder'>
                            <span>{OfTitle} =</span>
                            <div className='d-flex align-items-center justify-content-center flex-column mx-2'>
                                <span className='pb-1'>{Court ? NFPGJ : NFPP}</span>
                                <span style={{ borderTop: "1px solid #000" }}>
                                    {Court && (<span className='me-2'><span className='me-2'>{NP}</span>×</span>)}{KPM}
                                </span>
                            </div>
                            <span>× 100% = {Total}%</span>
                        </div>
                    </Typography>
                </Box>
            }
        >
            {children}
        </HTMLTooltip>
    )
}
// × {NOfM} - row 89
// × N<sub>m</sub> - row 62
export default CustomTooltip;

const titleStyle = {
    fontFamily: 'Inter, Helvetica, sans-serif',
    fontSize: '1rem',
    color: '#009ef7',
    marginBottom: '5px'
}

const formulaStyle = {
    fontFamily: 'Inter, Helvetica, sans-serif',
    fontSize: '1rem',
    marginBottom: '5px'
}

const parameterStyle = {
    fontFamily: 'Inter, Helvetica, sans-serif',
    fontSize: '1rem',
    color: '#99A1B7',
    marginBottom: '4px'
}

const calculatorStyle = {
    fontFamily: 'Inter, Helvetica, sans-serif',
    fontSize: '1rem',
    marginTop: '5px'
}