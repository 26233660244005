import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

const Error403: React.FC = () => {
    const { t } = useTranslation();

    return (
        <div className='card card-flush w-lg-650px pb-5'>
            <div className='card-body p-0 pb-10 pb-lg-20'>
                <div
                    className="py-10 d-flex align-items-center justify-content-center mb-10 w-100"
                    style={{ backgroundColor: "#ffc700", color: "#fff", borderRadius: '0.625rem' }}
                >
                    <svg xmlns="http://www.w3.org/2000/svg" height="96" width="96" viewBox="0 0 512 512">
                        <path fill="#ffffff" d="M256 32c14.2 0 27.3 7.5 34.5 19.8l216 368c7.3 12.4 7.3 27.7 .2 40.1S486.3 480 472 480H40c-14.3 0-27.6-7.7-34.7-20.1s-7-27.8 .2-40.1l216-368C228.7 39.5 241.8 32 256 32zm0 128c-13.3 0-24 10.7-24 24V296c0 13.3 10.7 24 24 24s24-10.7 24-24V184c0-13.3-10.7-24-24-24zm32 224a32 32 0 1 0 -64 0 32 32 0 1 0 64 0z"/>
                    </svg>
                </div>
                <h1 className='fw-bolder fs-2hx text-gray-900 mb-5'>{t("access_denied")}</h1>
                <div className='fw-semibold fs-5 text-gray-500 mb-1'>{t("not_authorized_for_this")}</div>
                <div className='fw-semibold fs-5 text-gray-500 mb-7'>{t("respect_others")}</div>
                <div className='fw-semibold fs-6 text-gray-500 mb-10'>{t("action_saved")}</div>
                <Link to='/home' className='btn btn-sm btn-primary'>{t("back_home")}</Link>
            </div>
        </div>
    );
}

export default Error403;