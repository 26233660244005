import axios from 'axios'
import {
  Menu,
  CreateMenuVM,
  UserVM,
  Group,
  CreateGroupVM,
  Role,
  Authorization,
  EditMenuVM,
  PersonalData,
  Institution,
  Language,
  DomainVM,
  Domain,
  CreateDomainVM,
  AddSubMenu,
  EditGroupVM,
  EditUserVM,
  EditPasswordVM,
  DocumentType,
  StatusesType,
  PriorityType,
  MonitorRequestVM,
  StatusesVM,
  City,
  Groups,
  RealRoles,
  RegisterRoleVM,
  EditDomainVM,
  AuthorizationActions,
  AddPrimaryVM,
} from './_models'

import ApiService from 'src/app/services/ApiService'

const API_URL = process.env.REACT_APP_API_URL
const CREATE_MENU = `${API_URL}/api/Administration/CreateMenu`
const EDIT_MENU = `${API_URL}/api/Administration/UpdateMenu`
const CREATE_GROUP = `${API_URL}/Manage/CreateGroup`
const SEARCH_USER = `${API_URL}/api/User/SearchUser`
const CREATE_USER = `${API_URL}/api/User/CreateUser`
const INSTITUTION_LIST = `${API_URL}/api/Home/Institutions`
const LANGUAGE_LIST = `${API_URL}/api/Home/Languages`
const SYSTEM_LANGUAGE_LIST = `${API_URL}/api/Home/SystemLanguages`
const CREATE_DOMAIN = `${API_URL}/api/Domains/CreateDomain`
const CREATE_SUBMENU = `${API_URL}/api/Administration/CreateSubMenu`
const EDIT_GROUP = `${API_URL}/api/Administration/UpdateGroup`
const EDIT_DOMAIN = `${API_URL}/api/Domains/UpdateDomain`
const EDIT_USER = `${API_URL}/api/User/EditUser`
const EDIT_PASSWORD = `${API_URL}/api/User/UpdatePassword`
const DOCUMENT_LIST = `${API_URL}/api/Home/DocumentList`
const STATUSES_LIST = `${API_URL}/api/Home/StatusesList`
const PRIORITIES_LIST = `${API_URL}/api/Home/PriorityList`
const MONITOR_REQUEST = `${API_URL}/api/Monitor/GetMonitorRequests`
const REQUEST_STATUSES_LIST = `${API_URL}/api/Monitor/RequestStatuses`
const CITY_LIST = `${API_URL}/api/User/CityList`
const ALL_ROLE = `${API_URL}/api/User/GetAllRole`
const CURRENT_ROLE = `${API_URL}/api/User/CurrentRole`
const REAL_ROLE_LIST = `${API_URL}/api/User/Roles`
const REGISTER_ROLE = `${API_URL}/api/User/AddRole`
const REAL_ROLE = `${API_URL}/api/Administration/RealRole`
const AUTHORIZATION_ACTION = `${API_URL}/api/Authorization/RuleAuthorization`
const ADD_PRIMARY = `${API_URL}/api/User/SetPrimaryRole`

export async function ListMenu(): Promise<Menu[]> {
  return ApiService.fetchData({
    url: '/api/Administration/AllMenu',
    method: 'get',
  })
}

export function CreateMenu(data: CreateMenuVM): Promise<any> {
  return axios.post(CREATE_MENU, data).then((response) => response.data)
}

export function EditMenu(data: EditMenuVM): Promise<any> {
  return axios.post(EDIT_MENU, data).then((response) => response.data)
}

export function EditPassword(data: EditPasswordVM): Promise<any> {
  return axios.post(EDIT_PASSWORD, data).then((response) => response.data)
}

export function SetPrimaryRole(data: AddPrimaryVM): Promise<any> {
  return axios.post(ADD_PRIMARY, data).then((response) => response.data)
}

export function AddGroup(data: RegisterRoleVM): Promise<any> {
  return axios.post(REGISTER_ROLE, data).then((response) => response.data)
}

export function EditGroup(data: EditGroupVM): Promise<any> {
  return axios.post(EDIT_GROUP, data).then((response) => response.data)
}

export function EditDomain(data: EditDomainVM): Promise<any> {
  return axios.post(EDIT_DOMAIN, data).then((response) => response.data)
}

export function EditUsers(data: EditUserVM): Promise<any> {
  return axios.post(EDIT_USER, data).then((response) => response.data)
}

export function CreateSubMenu(data: AddSubMenu): Promise<any> {
  return axios.post(CREATE_SUBMENU, data).then((response) => response.data)
}

export async function UserLists(data: MonitorRequestVM): Promise<any> {
  return ApiService.fetchData({
    url: '/api/User/UserList',
    method: 'post',
    data: data,
  })
}

export async function MonitorRequestsList(data: MonitorRequestVM): Promise<any> {
  return await ApiService.fetchData({
    url: '/api/Monitor/GetMonitorRequests',
    method: 'post',
    data: data
  })
}

export async function ListGroup(): Promise<Group[]> {
  return ApiService.fetchData({
    url: '/api/Administration/Groups',
    method: 'get',
  })
}

export function CreateGroup(data: CreateGroupVM): Promise<any> {
  return axios.post(CREATE_GROUP, data).then((response) => response.data)
}

export async function Roles(language: number): Promise<any> {
  return ApiService.fetchData({
    url: `/api/administration/Roles?Language=${language}`,
    method: 'get',
  })
}

export function RealRole(userId: string | null) {
  const url = `${REAL_ROLE_LIST}?userId=${userId}`
  return axios.get<RealRoles[]>(url).then((response) => {
    return response.data
  })
}

export async function AuthorizationMenu(roleId: string, language: number): Promise<any> {
  return ApiService.fetchData({
    url: `/api/Authorization/MenuAuthorization?roleId=${roleId}&language=${language}`,
    method: 'get',
  })
}

export function AuthorizationAction(roleId: string) {
  const url = `${AUTHORIZATION_ACTION}?roleId=${roleId}`
  return axios.get<AuthorizationActions[]>(url).then((response) => {
    return response.data
  })
}

export function SearchUsers(personalNumber: string) {
  const url = `${SEARCH_USER}?personalNumber=${personalNumber}`
  return axios.post(url).then((response) => {
    return response.data
  })
}

export function CreateUsers(data: PersonalData): Promise<any> {
  return axios.post(CREATE_USER, data).then((response) => response.data)
}

export function Institutions(language: number) {
  return axios.get<Institution[]>(`${INSTITUTION_LIST}?Language=${language}`).then((response) => {
    return response.data
  })
}

export function Cities(language: number) {
  return axios.get<City[]>(`${CITY_LIST}?Language=${language}`).then((response) => {
    return response.data
  })
}

export function Documents() {
  return axios.get<DocumentType[]>(DOCUMENT_LIST).then((response) => {
    return response.data
  })
}

export function Statuses() {
  return axios.get<StatusesType[]>(STATUSES_LIST).then((response) => {
    return response.data
  })
}

export function Priorities() {
  return axios.get<PriorityType[]>(PRIORITIES_LIST).then((response) => {
    return response.data
  })
}

export function Languages(language: number) {
  return axios.get<Language[]>(`${LANGUAGE_LIST}?Language=${language}`).then((response) => {
    return response.data
  })
}

export function SystemLanguages() {
  return axios.get<Language[]>(SYSTEM_LANGUAGE_LIST).then((response) => {
    return response.data
  })
}

export async function Domains(): Promise<any> {
  return ApiService.fetchData({
    url: '/api/Domains/DomainList',
    method: 'get',
  })
}

export async function DomainLists(data: DomainVM): Promise<any> {
  return ApiService.fetchData({
    url: `/api/Domains/Domain?name=${data.Name}`,
    method: 'post',
  })
}

export function CreateDomain(data: CreateDomainVM): Promise<any> {
  return axios.post(CREATE_DOMAIN, data).then((response) => response.data)
}

export function ListStatuses() {
  return axios.get<StatusesVM[]>(REQUEST_STATUSES_LIST).then((response) => {
    return response.data
  })
}

export function RealRoleByUser(userId: string, Language: number) {
  const url = `${REAL_ROLE}?userId=${userId}&Language=${Language}`
  return axios.get(url).then((response) => {
    return response.data
  })
}

export function GetAllRole(userId: string | null) {
  const url = `${ALL_ROLE}?userId=${userId}`
  return axios.get(url).then((response) => {
    return response.data
  })
}

export function GetCurrentRole(userId: string | null) {
  const url = `${CURRENT_ROLE}?userId=${userId}`
  return axios.get(url).then((response) => {
    return response.data
  })
}