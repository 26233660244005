import clsx from 'clsx'
import { FC, useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { KTIcon, toAbsoluteUrl } from '../../../../helpers'
import axios from 'axios'
import { t } from 'i18next';

import { format } from 'date-fns'
import { UserHeaderNotificationVM } from '../../../../../app/modules/profile/components/core/_models'
import { getUserHeaderNotification, markNotificationAsRead } from '../../../../../app/modules/profile/components/core/_request'
import './notification_details.css'



const HeaderNotificationsMenu: FC = () => {
  const [notifications, setNotifications] = useState<UserHeaderNotificationVM[]>([]);

  useEffect(() => {
    const fetchNotifications = async () => {
      try {
        const data = await getUserHeaderNotification();
        setNotifications(data);
      } catch (error) {
        console.error('Failed to fetch notifications', error);
      }
    };

    fetchNotifications();
  }, []);

  

  const handleNotificationClick = async (notificationId: number, requestTranslateId: string | null, URL:string) => {
    try {
      await markNotificationAsRead(notificationId);
      console.log('Navigating to request page with ID:', requestTranslateId);
      window.location.href = `${URL}/${requestTranslateId}`;
    } catch (error) {
      console.error('Error marking notification as read:', error);
    }
  };

  const formatDate = (dateString: string) => {
    const date = new Date(dateString);
    return format(date, 'dd/MM/yyyy HH:mm');
  }

  

  return (
    <div
      className='menu menu-sub menu-sub-dropdown menu-column w-350px w-lg-375px'
      data-kt-menu='true'
    >
      <div
        className='d-flex flex-column bgi-no-repeat rounded-top'
        style={{ backgroundImage: `url('${toAbsoluteUrl('/media/misc/menu-header-bg.jpg')}')` }}
      >
        <h3 className='text-white fw-bold px-9 mt-15 mb-6'>
          {t('notifications')}<span className='fs-4 opacity-85 ps-4'>{notifications.length}</span>
        </h3>
      </div>

      <div className='tab-content'>
        <div className='tab-pane fade show active' id='kt_topbar_notifications_1' role='tabpanel'>
          <div className='scroll-y mh-325px my-5 px-8'>
            {notifications.map(notification => (
              <div key={notification.NotificationId} className='d-flex flex-stack py-4'>
                <div className='d-flex align-items-center'>
                  <div className='symbol symbol-25px me-4'>
                    <span className={clsx('symbol-label', `bg-light`)}>
                      <KTIcon iconName={notification.Icon} className={`fs-2 text`} />
                    </span>
                  </div>

                  <div className='mb-0 me-2' >
                    <a href={notification.Url} className='fs-7 text-gray-800 text-hover-primary fw-bolder'onClick={(e) =>{
                              e.preventDefault();
                            
                               handleNotificationClick(notification.NotificationId, notification.RequestTranslateId, notification.Url);
                              }}>
                      {notification.Title}
                    </a>
                    <div className='text-gray-400 fs-7 notification-details'  >{notification.Details}</div>
                  </div>
                </div>

                <span className='badge badge-light text-primary fs-8'>{formatDate(notification.InsertedDate)}</span>
              </div>
            ))}
          </div>

          <div className='py-3 text-center border-top'>
            <Link
              to='/crafted/pages/profile'
              className='btn  btn-active-color-primary'
            >
              {t('view_all')}<KTIcon iconName='arrow-right' className='fs-5' />
            </Link>
          </div>
        </div>
      </div>
    </div>
  )
}


export { HeaderNotificationsMenu }

