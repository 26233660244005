/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC, useEffect, useState} from 'react'
import {Link} from 'react-router-dom'
import {getAuth, updateAuth, useAuth} from '../../../../app/modules/auth'
import Avatar from 'react-avatar'
import {UserData} from '../../../../../src/app/modules/profile/components/core/_models'
import {getUserData} from '../../../../../src/app/modules/profile/components/core/_request'
import {useIntl} from 'react-intl'
import {RealRoleByUser} from 'src/app/modules/administration/core/_requests'
import axios from 'axios'

const HeaderUserMenu: FC = () => {
  const API_URL = process.env.REACT_APP_API_URL
  const {currentUser, logout} = useAuth()
  const [userData, setUserData] = useState<UserData | null>(null)
  const [roleData, setRoleData] = useState<{RoleId: string; RoleName: string; IsActive: boolean}[]>(
    []
  )
  const intl = useIntl()

  const languages = {sq: 1, en: 2, sr: 3}
  const currentLanguage = languages[intl.locale] || languages['sq']

  useEffect(() => {
    const fetchUserData = async () => {
      const userDataResponse = await getUserData()
      setUserData(userDataResponse)
      if (currentUser?.id) {
        try {
          const response = await axios.get(`${API_URL}/api/Administration/RealRole`, {
            params: {userId: currentUser.id, Language: currentLanguage},
          })

          if (response.status === 200) {
            setRoleData(response.data)
          } else if (response.status === 404) {
            setRoleData([])
          }
        } catch (error) {
          if (axios.isAxiosError(error) && error.response?.status !== 404) {
            console.error('Failed to fetch roles:', error.message)
          }
        }
      }
    }

    fetchUserData()
  }, [currentUser, currentLanguage, API_URL])

  const AvatarWithInitials1 = ({name}) => {
    return <Avatar name={name} size='40' color='#0056b3' />
  }

  const toggleRoleActiveStatus = async (previousRoleId, newRoleId) => {
    try {
      const response = await axios.post(`${API_URL}/api/Administration/ChangeRole`, {
        previousRoleId,
        newRoleId
      })
      return response.data
    } catch (error) {
      console.error('Failed to update role status:', error)
      throw error
    }
  }

  const handleRoleChange = async (index) => {
    const newRoleData = [...roleData]
    const role = newRoleData[index]

    const currentlyActiveRole = roleData.find((r) => r.IsActive && r.RoleId !== role.RoleId)
    const previousRoleId = currentlyActiveRole ? currentlyActiveRole.RoleId : null
    const newRoleId = role.RoleId

    try {
      await toggleRoleActiveStatus(previousRoleId, newRoleId)

      if (currentlyActiveRole) {
        const currentIndex = newRoleData.findIndex((r) => r.RoleId === currentlyActiveRole.RoleId)
      }

      setRoleData(newRoleData)
      window.location.reload();

      // try {
        
      //   const authData = getAuth()
      //   const refreshResponse = await axios.post(`${API_URL}/RefreshToken`, {
      //     ExistingToken: authData?.api_token,
      //   })

      //   const newToken = refreshResponse.data.token.result
      //   updateAuth({api_token: newToken})

      //   const baseURL = window.location.origin
      //   const targetURL = new URL('/home', baseURL)
      //   window.location.replace(targetURL.toString())
      // } catch (refreshError) {
      //   const baseURL = window.location.origin
      //   const targetURL = new URL('/error/403', baseURL)
      //   window.location.replace(targetURL.toString())
      // }
    } catch (error) {
      console.error('Error updating role:', error)
    }
  }

  return (
    <div
      className='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg menu-state-primary fw-bold py-4 fs-6 w-275px'
      data-kt-menu='true'
    >
      <div className='menu-item px-3'>
        <div className='menu-content d-flex align-items-center px-3'>
          <div className='symbol symbol-50px me-5'>
            {/* <img alt='Logo' src={toAbsoluteUrl('/media/avatars/300-1.jpg')} /> */}
            <AvatarWithInitials1 name={userData && `${userData.Firstname} ${userData.Lastname}`} />
          </div>

          <div className='d-flex flex-column'>
            <div className='fw-bolder d-flex align-items-center fs-6'>
              {currentUser?.firstName} {currentUser?.lastName}
              <span className='badge badge-light-success fw-bolder fs-8 px-2 py-1 ms-2'>
                {currentUser?.role}
              </span>
            </div>
            <a href='#' className='fw-bold text-muted text-hover-primary fs-7'>
              {currentUser?.email}
            </a>
          </div>
        </div>
      </div>

      <div className='separator my-2'></div>

      <div className='menu-item px-5'>
        <Link to={'/crafted/pages/profile'} className='menu-link px-5'>
          {intl.formatMessage({id: 'MY_PROFILE'})}
        </Link>
      </div>

      <div className='separator my-2'></div>

      {roleData.length > 1 &&
        roleData.map((role, index) => (
          <div key={index} className='menu-item px-5'>
            <div className='d-flex justify-content-between align-items-center menu-link px-5' onClick={() => handleRoleChange(index)}>
              <span>{role.RoleName}</span>
              <div className='form-check form-check-custom form-check-solid form-check-sm'>
                <input
                  className='form-check-input'
                  type='checkbox'
                  checked={role.IsActive}
                  onChange={() => handleRoleChange(index)}
                />
              </div>
            </div>
          </div>
        ))}

      {roleData.length > 1 && <div className='separator my-2'></div>}

      <div className='menu-item px-5'>
        <a onClick={logout} className='menu-link px-5'>
          {intl.formatMessage({id: 'LOGOUT'})}
        </a>
      </div>
    </div>
  )
}

export {HeaderUserMenu}
