export interface AuthModel {
  api_token: string;
  role: string;
  language: string;
}

export interface UserModel {
  api_token: string
  id: string
  username: string
  password: string | undefined
  email: string
  role: string
  firstName: string
  lastName: string
  fullname?: string
  occupation?: string
  companyName?: string
  phone?: string
  roles?: Array<number>
  pic?: string
  language?: 'en' | 'de' | 'es' | 'fr' | 'ja' | 'zh' | 'ru'
  timeZone?: string
  website?: 'https://keenthemes.com'
  auth?: AuthModel
}

export interface MenuModel {
  MenuId: number
  ParentId: number
  Name: string
  Path: string
  Icon: string
  Type: number
  HasSub: boolean
  HasAccess: boolean
  OrderNo: number
  Active: boolean
}

export enum LanguageEnum {
  ALBANIAN = 1,
  ENGLISH,
  SERBIAN
}
