import {useEffect, useState} from 'react'
import {getAuth} from '../auth'
import {
  ADMIN,
  CENTRAL_LEVEL,
  MONITORING,
  REGIONAL,
  TRANSLATOR,
  OTHER,
} from '../../constants/roles.constants'
import AdminDashboard from './dashboards/Admin'
import TranslatorDashboard from './dashboards/Translator'
import RegionalDashboard from './dashboards/Regional'
import CentralLevelDashboard from './dashboards/CentralLevel'
import MonitoringDashboard from './dashboards/Monitoring'
import RequestForTranslation from '../requestForTranslation'

const Home: React.FC = () => {
  const [role, setRole] = useState<string>()

  useEffect(() => {
    var authData = getAuth()
    setRole(authData?.role)
  }, [])

  // const renderDashboard = () => {
  //   switch (role) {
  //     case ADMIN:
  //       return <CentralLevelDashboard />
  //     case TRANSLATOR:
  //       return <CentralLevelDashboard />
  //     case REGIONAL:
  //       return <CentralLevelDashboard />
  //     case CENTRAL_LEVEL:
  //       return <CentralLevelDashboard />
  //     case MONITORING:
  //       return <CentralLevelDashboard />
  //     case OTHER:
  //       return <CentralLevelDashboard />
  //     default:
  //       return <div>No dashboard available for this role</div>
  //   }
  // }

  // me kerkese qe per cdo grup me u shfaq dashboard e njejte
  const renderDashboard = () => {
    return <CentralLevelDashboard />
  }

  return <div className='row g-5 g-xl-10 mb-5 mb-xl-10'>{renderDashboard()}</div>
}

export default Home
