import { useEffect, useState } from "react";
import { InterpreterPerformanceDashboardVM, ListYearsVM, PerformanceDashboardVM, SearchPerformance } from "../../core/Models";
import { PerformanceDashboardRequest, InterpreterPerformanceDashboardRequest, ListYears, PerformanceRegionReport, PerformanceCourtReport, PerformanceInterpreterReport } from "../../core/RequestsAPI";
import moment from "moment";
import { Backdrop, CircularProgress, MenuItem, Select, SelectChangeEvent } from "@mui/material";
import ProgressWidget from "../ProgressWidget";
import { Form, Formik } from "formik";
import { getTranslatedMonths, getTranslatedPeriods } from "../../../../constants/date.constants";
import { Button } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { ReportType } from "../../../shared/core/Models";
import PrintDropDown from "../PrintDropDown";

const PerformanceTab: React.FC = () => {
    const { t } = useTranslation();

    const months = getTranslatedMonths(t);
    const periods = getTranslatedPeriods(t);

    const [loading, setLoading] = useState<boolean>(false);
    const [showRegion, setShowRegion] = useState<boolean>(false);
    const [showInterpreter, setShowInterpreter] = useState<boolean>(false);

    const [dashboardData, setDashboardData] = useState<PerformanceDashboardVM[] | null>();
    const [dashboardRegionData, setDashboardRegionData] = useState<PerformanceDashboardVM[] | null>();
    const [dashboardInterpreterData, setDashboardInterpreterData] = useState<InterpreterPerformanceDashboardVM[] | null>();

    const [years, setYears] = useState<ListYearsVM[] | null>();
    const [ascYears, setAscYears] = useState<ListYearsVM[] | null>();

    const [selectedRegionId, setSelectedRegionId] = useState<number | null>(null);
    const [selectedInstitutionId, setSelectedInstitutionId] = useState<number | null>(null);
    const [selectedMainCourt, setSelectedMainCourt] = useState<number | null>(null);

    const [selectedPeriod, setSelectedPeriod] = useState<string>('');

    const initialValues: SearchPerformance = {
        RegionID: 0,
        InstitutionID: 0,
        Year: moment().year(),
        Month: moment().month() + 1,
        Period: 1,
        From: 0,
        To: 0,
        ReportType: ReportType.PDF
    }
    const [updatedValues, setUpdatedValues] = useState<SearchPerformance>(initialValues);

    const sortNumbers = (data: ListYearsVM[], direction: "asc" | "desc"): ListYearsVM[] => {
        return data.slice().sort((a, b) => { return direction === "desc" ? b.Year - a.Year : a.Year - b.Year; });
    }

    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);
            await PerformanceDashboardRequest(updatedValues)
                .then((a) => {
                    setDashboardData(a);
                    setSelectedPeriod(a ? a[0].Period : '');
                }).catch((error) => {
                    console.error("There has been an error while fetching data for dashboard:", error);
                }).finally(() => {
                    setLoading(false);
                });

            await ListYears()
                .then((a) => {
                    setAscYears(sortNumbers(a, "asc"));
                    setYears(sortNumbers(a, "desc"));
                }).catch((error) => console.log(error));
        }

        if (showRegion) {
            const fetchRegionData = async () => {
                const data: SearchPerformance = {
                    ...updatedValues,
                    RegionID: selectedRegionId || 0
                };

                await PerformanceDashboardRequest(data)
                    .then((a) => {
                        setDashboardRegionData(a);
                    }).catch((error) => {
                        console.error("There has been an error while fetching data for dashboard:", error);
                    }).finally(() => {
                        setLoading(false);
                    });
            }

            fetchRegionData();
        }

        if (updatedValues.Period !== 13) {
            fetchData();
        }
    }, [updatedValues]);

    const handleClose = () => setLoading(false);

    if (!dashboardData) {
        return <Backdrop
                    sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                    open={loading}
                    onClick={handleClose}
                >
                    <CircularProgress color="inherit"/>
                </Backdrop>;
    }

    const handleRegionCardClick = async (regionId: number, institutionId: number) => {
        setShowInterpreter(false);
        setLoading(true);
        const isSameRegion = selectedRegionId === regionId;

        const data: SearchPerformance = {
            ...updatedValues,
            RegionID: isSameRegion ? 0 : regionId
        };

        try {
            if (!isSameRegion) {
                const response = await PerformanceDashboardRequest(data);
                setDashboardRegionData(response);
            }
            setShowRegion(!isSameRegion && regionId !== 0);

            if (regionId === 0) {
                setSelectedMainCourt(institutionId);
                handleInstitutionCardClick(institutionId);
            } else {
                setSelectedMainCourt(null);
            }
        } catch (error) {
            console.error("There has been an error while fetching data for dashboard:", error);
        } finally {
            setSelectedRegionId(isSameRegion ? null : regionId);
            setLoading(false);
        }
    }

    const handleInstitutionCardClick = async (institutionId: number) => {
        setLoading(true);
        const isSameInstitution = selectedInstitutionId === institutionId;

        const data: SearchPerformance = {
            ...updatedValues,
            InstitutionID: isSameInstitution ? 0 : institutionId
        };

        try {
            setDashboardInterpreterData([]);
            if (!isSameInstitution) {
                const response = await InterpreterPerformanceDashboardRequest(data);
                setDashboardInterpreterData(response);
                if (response != null) {
                    setShowInterpreter(response.length > 0);
                }
            } else {
                setShowInterpreter(false);
            }
        } catch (error) {
            console.error("There has been an error while fetching data for dashboard:", error);
        } finally {
            setSelectedInstitutionId(isSameInstitution ? null : institutionId);
            setLoading(false);
        }
    }

    const handleSubmit = async () => {
        setLoading(true);
        await PerformanceDashboardRequest(updatedValues)
            .then((a) => {
                setDashboardData(a);
                setSelectedPeriod(a ? a[0].Period : '');
            }).catch((error) => {
                console.error("There has been an error while fetching data for dashboard:", error);
            }).finally(() => {
                setLoading(false);
            });

        if (showRegion) {
            const data: SearchPerformance = {
                ...updatedValues,
                RegionID: selectedRegionId || 0
            };

            await PerformanceDashboardRequest(data)
                .then((a) => {
                    setDashboardRegionData(a);
                }).catch((error) => {
                    console.error("There has been an error while fetching data for dashboard:", error);
                }).finally(() => {
                    setLoading(false);
                });
        }
    }

    const handleReport = async (reportType: ReportType, type: 'region' | 'court' | 'interpreter') => {
        setLoading(true);

        const data: SearchPerformance = {
            ... updatedValues,
            RegionID: selectedRegionId ?? 0,
            InstitutionID: type === 'interpreter' ? (selectedInstitutionId ?? 0) : 0,
            ReportType: reportType
        }

        var url = "";

        if (type === 'region') {
            const response = await PerformanceRegionReport(data);
            url = window.URL.createObjectURL(response);
        } else if (type === 'court') {
            const response = await PerformanceCourtReport(data);
            url = window.URL.createObjectURL(response);
        } else {
            const response = await PerformanceInterpreterReport(data);
            url = window.URL.createObjectURL(response);
        }

        const documentName = {
            region: "Raporti performancës për regjione",
            court: "Raporti performancës për degët e gjykatave",
            interpreter: "Raporti performancës për përkthyesit"
        };

        const link = document.createElement('a');
        link.target = '_blank';
        link.href = url;
        link.setAttribute('download', documentName[type]);
        document.body.appendChild(link);
        link.click();
        link.remove();

        setLoading(false);
    }

    return (
        <>
            <div className="card card-xl-stretch mb-xl-8">
                <div className="card-header pt-5 pb-3">
                    <h3 className="card-title align-items-start flex-column">
                        <span className="card-label fw-bold fs-3 mb-1">
                            {t("court_performance")} <br/> {selectedPeriod ? `(${selectedPeriod})` : ""}
                        </span>
                    </h3>
                    <div className="card-toolbar" style={{ width: '50%' }}>
                        <Formik
                            initialValues={initialValues}
                            onSubmit={handleSubmit}
                        >
                            {(formProps) => (
                                <Form style={{ width: '100%' }}>
                                    <div className="row">
                                        <div className={`col-sm-12 ${updatedValues.Period === 13 ? "col-md-3" : "col-md-4"}`}>
                                            <label className="form-label">{t("period")}</label>
                                            <Select
                                                id="Period"
                                                name="Period"
                                                size="small"
                                                displayEmpty={true}
                                                fullWidth={true}
                                                className="demo-select-small-label"
                                                style={{ fontFamily: "Inter, Helvetica, sans-serif" }}
                                                inputProps={{ 'aria-label': 'Without label' }}
                                                onBlur={formProps.handleBlur}
                                                value={updatedValues.Period !== null ? String(updatedValues.Period) : ''}
                                                onChange={(event: SelectChangeEvent) => {
                                                    setUpdatedValues({
                                                        ...updatedValues,
                                                        Period: event.target.value === "" ? 1 : Number(event.target.value)
                                                    });
                                                }}
                                            >
                                                {periods.map((period) => (
                                                    <MenuItem
                                                        style={{ fontFamily: "Inter, Helvetica, sans-serif" }}
                                                        key={period.PeriodId}
                                                        value={period.PeriodId}
                                                        selected={period.PeriodId === 1}
                                                    >{period.Name}</MenuItem>
                                                ))}
                                            </Select>
                                        </div>
                                        {updatedValues.Period === 13 ? (
                                            <>
                                                <div className="col-sm-12 col-md-3">
                                                    <label className="form-label">{t("from")}</label>
                                                    <Select
                                                        id="From"
                                                        name="From"
                                                        size="small"
                                                        displayEmpty={true}
                                                        fullWidth={true}
                                                        className="demo-select-small-label"
                                                        style={{ fontFamily: "Inter, Helvetica, sans-serif" }}
                                                        inputProps={{ 'aria-label': 'Without label' }}
                                                        onBlur={formProps.handleBlur}
                                                        value={updatedValues.From !== null ? String(updatedValues.From) : ''}
                                                        onChange={(event: SelectChangeEvent) => {
                                                            setUpdatedValues({
                                                                ...updatedValues,
                                                                From: event.target.value === "" ? (moment().year()) : Number(event.target.value)
                                                            });
                                                        }}
                                                    >
                                                        {ascYears?.map((year) => (
                                                            <MenuItem
                                                                style={{ fontFamily: "Inter, Helvetica, sans-serif" }}
                                                                key={year.Year}
                                                                value={year.Year}
                                                            >{year.Year}</MenuItem>
                                                        ))}
                                                    </Select>
                                                </div>
                                                <div className="col-sm-12 col-md-3">
                                                    <label className="form-label">{t("to")}</label>
                                                    <Select
                                                        id="To"
                                                        name="To"
                                                        size="small"
                                                        displayEmpty={true}
                                                        fullWidth={true}
                                                        className="demo-select-small-label"
                                                        style={{ fontFamily: "Inter, Helvetica, sans-serif" }}
                                                        inputProps={{ 'aria-label': 'Without label' }}
                                                        onBlur={formProps.handleBlur}
                                                        value={updatedValues.To !== null ? String(updatedValues.To) : ''}
                                                        onChange={(event: SelectChangeEvent) => {
                                                            setUpdatedValues({
                                                                ...updatedValues,
                                                                To: event.target.value === "" ? (moment().year() + 1) : Number(event.target.value)
                                                            });
                                                        }}
                                                    >
                                                        {years?.map((year) => (
                                                            <MenuItem
                                                                style={{ fontFamily: "Inter, Helvetica, sans-serif" }}
                                                                key={year.Year}
                                                                value={year.Year}
                                                            >{year.Year}</MenuItem>
                                                        ))}
                                                    </Select>
                                                </div>
                                                <div className="col-sm-12 col-md-3">
                                                    <label className="form-label invisible">{t("until")}</label>
                                                    <Button
                                                        variant='primary'
                                                        type="submit"
                                                        className='fw-bold btn btn-sm btn-block'
                                                        style={{ width: '100%' }}
                                                    >{t("search")}</Button>
                                                </div>
                                            </>
                                        ) : (
                                            <>
                                                <div className="col-sm-12 col-md-4">
                                                    <label className="form-label">{t("year")}</label>
                                                    <Select
                                                        id="Year"
                                                        name="Year"
                                                        size="small"
                                                        displayEmpty={true}
                                                        fullWidth={true}
                                                        className="demo-select-small-label"
                                                        style={{ fontFamily: "Inter, Helvetica, sans-serif" }}
                                                        inputProps={{ 'aria-label': 'Without label' }}
                                                        onBlur={formProps.handleBlur}
                                                        value={updatedValues.Year !== null ? String(updatedValues.Year) : ''}
                                                        onChange={(event: SelectChangeEvent) => {
                                                            setUpdatedValues({
                                                                ...updatedValues,
                                                                Year: event.target.value === "" ? (moment().year()) : Number(event.target.value)
                                                            });
                                                        }}
                                                    >
                                                        {years?.map((year) => (
                                                            <MenuItem
                                                                style={{ fontFamily: "Inter, Helvetica, sans-serif" }}
                                                                key={year.Year}
                                                                value={year.Year}
                                                                selected={year.Year === (moment().year())}
                                                            >{year.Year}</MenuItem>
                                                        ))}
                                                    </Select>
                                                </div>
                                                <div className="col-sm-12 col-md-4">
                                                    <label className="form-label">{t("month")}</label>
                                                    <Select
                                                        id="Month"
                                                        name="Month"
                                                        size="small"
                                                        displayEmpty={true}
                                                        fullWidth={true}
                                                        className="demo-select-small-label"
                                                        style={{ fontFamily: "Inter, Helvetica, sans-serif" }}
                                                        inputProps={{ 'aria-label': 'Without label' }}
                                                        onBlur={formProps.handleBlur}
                                                        value={updatedValues.Month !== null ? String(updatedValues.Month) : ''}
                                                        onChange={(event: SelectChangeEvent) => {
                                                            setUpdatedValues({
                                                                ...updatedValues,
                                                                Month: event.target.value === "" ? (moment().month() + 1) : Number(event.target.value)
                                                            });
                                                        }}
                                                    >
                                                        {months.map((month) => (
                                                            <MenuItem
                                                                style={{ fontFamily: "Inter, Helvetica, sans-serif" }}
                                                                key={month.MonthId}
                                                                value={month.MonthId}
                                                                selected={month.MonthId === (moment().month() + 1)}
                                                            >{month.Name}</MenuItem>
                                                        ))}
                                                    </Select>
                                                </div>
                                            </>
                                        )}
                                    </div>
                                </Form>
                            )}
                        </Formik>
                    </div>
                </div>
                <div className="card-body">
                    <div className="d-flex justify-content-end align-items-center mb-5">
                        <PrintDropDown align='end' onClick={(type: ReportType) => handleReport(type, 'region')}/>
                    </div>
                    <div className="d-flex justify-content-start align-items-center" style={{ overflowX: 'auto', width: '100%', whiteSpace: 'nowrap' }}>
                        {dashboardData.map((data) => (
                            <ProgressWidget
                                key={data.InstitutionId}
                                chartColor='dark'
                                chartHeight='150px'
                                nfpgj={data.Nfpgj}
                                nfpp={data.Nfpp}
                                np={data.Np}
                                kpm={data.Kpm}
                                nofm={data.NOfM}
                                total={data.Performance}
                                court={true}
                                name={data.Institution}
                                percentage={data.Performance}
                                workload={data.Workload}
                                isActive={data.RegionId === 0 ? (selectedMainCourt === data.InstitutionId) : (selectedRegionId === data.RegionId)}
                                className="me-3"
                                onClick={() => handleRegionCardClick(data.RegionId, data.InstitutionId)}
                            />
                        ))}
                    </div>
                </div>
            </div>
            <div className={`card card-xl-stretch mb-xl-8 ${showRegion ? '' : 'd-none'}`}>
                <div className="card-header d-flex flex-stack pt-5 pb-3">
                    <h3 className="card-title align-items-start flex-column">
                        <span className="card-label fw-bold fs-3 mb-1">{t("court_branch_performance")}</span>
                    </h3>
                    <div className="card-toolbar d-inline-flex justify-content-end align-items-center" style={{ width: '30%' }}>
                        <PrintDropDown align='end' onClick={(type: ReportType) => handleReport(type, 'court')}/>
                    </div>
                </div>
                <div className="card-body">
                    <div className="d-flex justify-content-start align-items-center" style={{ overflowX: 'auto', width: '100%', whiteSpace: 'nowrap' }}>
                        {dashboardRegionData?.map((data) => (
                            <ProgressWidget
                                key={data.InstitutionId}
                                chartColor='dark'
                                chartHeight='150px'
                                nfpgj={data.Nfpgj}
                                nfpp={data.Nfpp}
                                np={data.Np}
                                kpm={data.Kpm}
                                nofm={data.NOfM}
                                total={data.Performance}
                                court={true}
                                name={data.Institution}
                                percentage={data.Performance}
                                workload={data.Workload}
                                isActive={selectedInstitutionId === data.InstitutionId}
                                className="me-3"
                                onClick={() => handleInstitutionCardClick(data.InstitutionId)}
                            />
                        ))}
                    </div>
                </div>
            </div>
            <div className={`card card-xl-stretch mb-xl-8 ${showInterpreter ? '' : 'd-none'}`}>
                <div className="card-header d-flex flex-stack pt-5 pb-3">
                    <h3 className="card-title align-items-start flex-column">
                        <span className="card-label fw-bold fs-3 mb-1">{t("translator_performance")}</span>
                    </h3>
                    <div className="card-toolbar d-inline-flex justify-content-end align-items-center" style={{ width: '30%' }}>
                        <PrintDropDown align='end' onClick={(type: ReportType) => handleReport(type, 'interpreter')}/>
                    </div>
                </div>
                <div className="card-body">
                    <div className="d-flex justify-content-start align-items-center" style={{ overflowX: 'auto', width: '100%', whiteSpace: 'nowrap' }}>
                        {dashboardInterpreterData?.map((data) => (
                            <ProgressWidget
                                key={data.InstitutionId}
                                chartColor='dark'
                                chartHeight='150px'
                                nfpgj={data.Nfpgj}
                                nfpp={data.Nfpp}
                                np={data.Np}
                                kpm={data.Kpm}
                                nofm={data.NOfM}
                                total={data.Performance}
                                court={false}
                                name={data.Interpreter}
                                percentage={data.Performance}
                                workload={data.Workload}
                                className="me-3"
                                isActive={false}
                            />
                        ))}
                    </div>
                </div>
            </div>
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={loading}
                onClick={handleClose}
            >
                <CircularProgress color="inherit"/>
            </Backdrop>
        </>
    )
}

export default PerformanceTab;